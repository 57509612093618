import React from "react";
import rankingBlack from "../../../../../../assets/Icons/MyHackathons/rankingBlack.svg";
import downloadCode from "../../../../../../assets/Icons/MyHackathons/downloadCode.svg";
import submissionsBlack from "../../../../../../assets/Icons/MyHackathons/submissionsBlack.svg";
import lastSubmissionBlack from "../../../../../../assets/Icons/MyHackathons/lastSubmissionBlack.svg";
import registered from "../../../../../../assets/Icons/MyHackathons/registered.svg";
import "./HackathonUserInterviewDetails.css";
import moment from "moment";
import { LoadingShape } from "react-rainbow-components";

function HackathonUserInterviewDetails(props) {
  const formatDate = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("Do MMMM");

    if (formattedDate === "Invalid date") {
      var year = strdate[2];
      var month = strdate[0];
      var day = strdate[1];

      var formattedDate = moment({
        year: Number(year),
        month: Number(month) - 1,
        day: Number(day),
      }).format("Do MMMM");
    }
    return formattedDate;
  };

  function handleSubmissionFile() {
    // window.open(
    //   "https://machinehack.futurekrux.com/enterprise/lastsubmission/download/predict_the_flight_ticket_price_hackathon/610d1e9644215fb3400e3616/6116586b687a1ad086100328",
    //   "_blank"
    // );
    if (process.env.REACT_APP_ENV_TYPE === "production") {
      window.open(
        "https://api.machinehack.com/enterprise/lastsubmission/download/" +
          props?.userInfo.href +
          "/" +
          props?.uId +
          "/" +
          props?.userInfo.file,
        "_blank"
      );
    } else {
      window.open(
        "https://machinehack.futurekrux.com/enterprise/lastsubmission/download/" +
          props?.userInfo.href +
          "/" +
          props?.uId +
          "/" +
          props?.userInfo.file,
        "_blank"
      );
    }
  }
  return (
    <div className={`${(!props.isLoading && Object.keys(props?.userInfo || {}).length > 0) || props.isLoading ? "hackathonUserInterviewDetails d-flex justify-content-between" : ""}`}>
      {props.isLoading || props.isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "10%", height: "20px" }} />
      ) : (
        props?.selectedUserRank && (
          <div>
            <img className="me-2" src={rankingBlack} />
            <div>
              Rank
              <div>{props?.selectedUserRank}</div>
            </div>
          </div>
        )
      )}

      {props.isLoading || props.isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "10%", height: "20px" }} />
      ) : (
        props?.userInfo?.created && (
          <div>
            <img className="me-2" src={registered} />
            <div>
              Registered on
              <div>{formatDate(props?.userInfo?.created)}</div>
            </div>
          </div>
        )
      )}

      {props.isLoading || props.isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "10%", height: "20px" }} />
      ) : (
        props?.userInfo?.no_of_submissions > 0 && (
          <div>
            <img className="me-2" src={submissionsBlack} />
            <div>
              No. of Submissions
              <div>{props?.userInfo?.no_of_submissions}</div>
            </div>
          </div>
        )
      )}
      {props.isLoading || props.isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "10%", height: "20px" }} />
      ) : (
        props?.userInfo?.last_submission && (
          <div>
            <img className="me-2" src={lastSubmissionBlack} />
            <div>
              Last Submission
              <div>{formatDate(props?.userInfo?.last_submission)}</div>
            </div>
          </div>
        )
      )}
      {props.isLoading || props.isLeaderBoardLoading ? (
        <LoadingShape
          className="me-3"
          style={{ width: "10%", height: "20px" }}
        />
      ) : (
        <>
        {Object.keys(props?.userInfo || {}).length > 0 && <div
          className="hackathonDownloadCode activeTab cursor-pointer"
          onClick={() => {
            handleSubmissionFile();
          }}
        >
          Download Code
          <img className="ms-2" src={downloadCode} />
        </div>}
        </>
      )}
    </div>
  );
}

export default HackathonUserInterviewDetails;
