import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";
import "./interviewpage.css";
import QuestionType from "./components/QuestionType";
import SelectAssessment from "./SelectAssessment";
import goBackIcon from "../../../assets/Icons/MyHackathons/arrowLeftBlack.svg";
import waitingMH from "../../../assets/Images/waitinglobby.gif";
import SelectAssessmentType from "./SelectAssessmentType";
import axios from "axios";
import { BootcampBackend, Machinehack_Url } from "../../../backend";
import { isAuthenticated } from "../../../helper/user";
import moment from "moment";
import { socket } from "../../../socket/Socket";
import { Helmet } from "react-helmet";

export default function InterviewPage(props) {
  const [showAssessments, setshowAssessments] = useState(false);
  const [showAssessmentType, setShowAssessmentType] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [tabs, setTabs] = useState([]);
  const interviewToken = props.match.params.tokenId;
  const [tokenData, settokenData] = useState({});
  const [interviewData, setinterviewData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [Listoftabs, setListofTabs] = useState([]);
  const history = useHistory();
  const selectedQuesType = (data) => {
    setSelectedQuestions(data);
    if (data.id === "3") {
      setshowAssessments(true);
    } else {
      setshowAssessments(false);
    }
  };
  const createRounddata = async () => {
    await axios
      .post(
        `${BootcampBackend}/interview/createInterviewRoundData`,
        {
          interviewToken: interviewToken,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function parseJwt(token) {
    if (token) {
      try {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        settokenData(JSON.parse(jsonPayload));
        return JSON.parse(jsonPayload);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      console.error("Invalid token");
    }
  }
  const getInterviewRoundData = async () => {
    await axios
      .get(
        `${BootcampBackend}/interview/getInterviewRoundTabData/${interviewToken}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setTabs(res.data.result.interviewData?.tabs);
        setinterviewData(res.data.result.interviewData);
        setListofTabs(res.data.result.interviewData?.tabs);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInterviewData = async () => {
    await axios
      .get(
        `${BootcampBackend}/interview/getInterviewRoundDataUserSide/${interviewToken}`
      )
      .then((res) => {
        if (
          parseJwt(interviewToken).companyId !== isAuthenticated().eid &&
          !res.data.result.interviewData.candidateInLobby
        ) {
          window.location.assign(`${Machinehack_Url}/#login/${interviewToken}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSocketTabsData();
    getInterviewData();
    parseJwt(interviewToken);
    getInterviewRoundData();
    createRounddata();
  }, [selectedQuestions]);

  const getSocketTabsData = () => {
    socket.on("connect", () => {
      // console.log(socket.connected, socket.id);
    });
    socket.on("EnterpriseConnectionResponse", (payload) => {
      // console.log(payload);
    });
    socket.on("CreateTabResponse", (payload) => {
      if (interviewToken === payload.interviewRoundId.interviewToken)
        setTabs(payload.tabs);
    });
  };
  return (
    <div>
      <Helmet>
        <style>{".zopim { display: none !important; }"}</style>
      </Helmet>
      <Header isInterview={true} />
      {!isLoading && (
        <>
          {tokenData.companyId === isAuthenticated().eid ? (
            <div className="">
              <div className=" body-margin-top scroll-Off">
                <div
                  className={`d-flex align-items-center assessmentSubHeaderNav justify-content-between`}
                >
                  {" "}
                  {!showAssessments && (
                    <div className="d-flex">
                      <img
                        src={goBackIcon}
                        alt="goback"
                        className="img-fluid gobackIconAssess me-2 cursor-pointer"
                        loading="lazy"
                        onClick={() => history.push(`/interviews`)}
                      />
                      <p className="assessNav">Back</p>
                    </div>
                  )}
                  {showAssessments && (
                    <div
                      className="d-flex mr-2"
                      onClick={() => (
                        setshowAssessments(false), setShowAssessmentType("")
                      )}
                    >
                      <img
                        src={goBackIcon}
                        alt="goback"
                        className="img-fluid gobackIconAssess me-2 cursor-pointer"
                        loading="lazy"
                        onClick={() =>
                          history.push(`/interview-paper/${interviewToken}`)
                        }
                      />
                      <p className="assessNav">Go to Selection</p>
                    </div>
                  )}
                  <ul className="candidateDetails text-center mb-0">
                    <li>
                      {interviewData?.interviewId?.candidateName
                        ? interviewData?.interviewId?.candidateName + " - "
                        : interviewData?.interviewId?.candidateEmail
                        ? interviewData?.interviewId?.candidateEmail + " - "
                        : ""}
                      {tokenData.interviewType === "instant"
                        ? "Instant Interview"
                        : interviewData?.interviewId?.interviewTitle}
                    </li>
                    <li>
                      {moment(
                        interviewData?.interviewRoundId?.interviewStartTime
                      ).format("LT")}
                    </li>
                  </ul>
                  <div className=""></div>
                </div>
                {showAssessments ? (
                  <>
                    {showAssessmentType === "" && (
                      <div className="">
                        <div className=" col-12 mx-auto interviewAssessType pt-1 d-flex justify-content-center align-items-center">
                          <SelectAssessmentType
                            isFirstTime={true}
                            setShowAssessmentType={(value) =>
                              setShowAssessmentType(value)
                            }
                          />
                        </div>
                      </div>
                    )}
                    {showAssessmentType !== "" && (
                      <SelectAssessment
                        isFirstTime={true}
                        interviewToken={interviewToken}
                        showAssessmentType={showAssessmentType}
                        noAssessmentTextColor="#3c3e4d"
                        interviewData={interviewData}
                      />
                    )}
                  </>
                ) : (
                  <div className="question-Type">
                    <div className="col-lg-11 col-md-10 col-12 mx-auto interviewQuestionHold pt-1">
                      <QuestionType
                        isFirstTime={true}
                        selectedQuesType={selectedQuesType}
                        Listoftabs={Listoftabs}
                        interviewToken={interviewToken}
                        interviewData={interviewData}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {tabs?.length > 0 ? (
                selectedQuestions &&
                history.push(
                  `/interview-paper/${tabs[0].tabID}/${interviewToken}`
                )
              ) : (
                <div
                  className=""
                  style={{ background: "#fff", marginTop: "60px" }}
                >
                  <br />
                  <br />
                  <div className="candidateswaitinglobby px-5 pb-5 col-10 offset-lg-1">
                    <img
                      src={waitingMH}
                      alt="lobby"
                      className="img-fluid gobackIcon me-2"
                      loading="lazy"
                    />
                    <p className="heytext mt-5">Hey</p>
                    <p className="infoaboutinterviewer">
                      Interviewer is setting up things
                    </p>
                    <p className="heytext">This might take time...</p>
                  </div>
                  <br />
                  <br />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
