import React, { useEffect, useRef, useState } from "react";
import "./Hackathon.css";

import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import hackathonSearch from "../../../../assets/Icons/MyHackathons/hackathonSearch.svg";
import NoResult from "../../../../assets/Icons/MyHackathons/NoResult.svg";
import hackathonRankFilter from "../../../../assets/Icons/MyHackathons/hackathonRankFilter.svg";
import SelectAssessment from "pages/PrivatePages/Interview/SelectAssessment";
import dropdownArrow from "../../../../assets/Icons/MyHackathons/dropdownArrow.svg";
import globelRanking from "../../../../assets/Icons/MyHackathons/globelRanking.svg";
import arrowDownRed from "../../../../assets/Icons/MyHackathons/arrowDownRed.svg";
import hackathonCalendarGreen from "../../../../assets/Icons/MyHackathons/hackathonCalendarGreen.svg";
import hackathonCalendarRed from "../../../../assets/Icons/MyHackathons/hackathonCalendarRed.svg";
import ArrowUp from "../../../../assets/Icons/MyHackathons/ArrowUp.svg";

//COMPONENTS
import UserDetails from "./UserDetails/UserDetails";

import axios from "axios";
import { API } from "../../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import CandidatesList from "../../../../components/CandidatesList";
import { useSelector } from "react-redux";
import { useSidebar } from "helper/SideBarContext";

const moment = require("moment");

export default function Hackathon(props) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSortBy, setShowSortBy] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [assessmentModalData, setAssessmentModalData] = useState("");
  const [hackathonData, setHackathonData] = useState({
    name: "",
    image: null,
    registration: null,
    leaderboard: null,
    end: null,
    start: "",
    href: "",
    resume_count: 0,
  });

  const [countdownCompleted, setCountdownCompleted] = useState(false);
  const [leaderboardUsers, setLeaderboardUsers] = useState([]);
  const [leaderboardLength, setLeaderBoardLength] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [hackathonList, setHackathonList] = useState([]);

  const [sort, setSort] = useState("Rank");

  const [isLoading, setIsLoading] = useState(true);
  const [isLeaderBoardLoading, setIsLeaderBoardLoading] = useState(true);
  const [isUserDetailsLoading, setIsUserDetailsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const { isSidebarCollapsed } = useSidebar();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  const user = useSelector((state) => state.auth.user);

  function handleSelectUser(id) {
    setSelectedUser(
      leaderboardUsers.filter((user) => {
        return user.User_Oid === id;
      })
    );
  }

  const formatDate = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("DD MMMM YYYY");

    if (formattedDate === "Invalid date") return "";
    return formattedDate;
  };

  const formatDateInMD = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("MMMM DD ");

    if (formattedDate === "Invalid date") return "";
    return formattedDate;
  };

  function handlePrevious() {
    const current = hackathonList.findIndex((hackathon) => {
      return hackathon === props.match.params.hackathonId;
    });
    if (current === 0) {
      return;
    }
    history.push("/myHackathons/" + hackathonList[current - 1]);
  }
  function handleNext() {
    const current = hackathonList.findIndex((hackathon) => {
      return hackathon === props.match.params.hackathonId;
    });
    if (current === hackathonList.length - 1) {
      return;
    }
    history.push("/myHackathons/" + hackathonList[current + 1]);
  }

  function handleSort(event) {
    const { value } = event.target;
    setSort(value);

    setActivePage(1);
    setIsLeaderBoardLoading(true);
    fetchLeaderboardData(1, props.match.params.hackathonId, value)
      .then((res) => {
        setIsLeaderBoardLoading(false);
        setLeaderBoardLength(res.data.leaderboard_length);
        setLeaderboardUsers(res.data.message);
        setSelectedUser(
          res.data.message.filter((item, index) => {
            return index === 0;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOnChangePagination(event, page) {
    setActivePage(page);
    setIsLeaderBoardLoading(true);
    fetchLeaderboardData(page, props.match.params.hackathonId, sort)
      .then((res) => {
        setIsLeaderBoardLoading(false);
        setLeaderBoardLength(res.data.leaderboard_length);
        setLeaderboardUsers(res.data.message);
        setSelectedUser(
          res.data.message.filter((item, index) => {
            return index === 0;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function fetchHackathonData(h_id) {
    try {
      const res = await axios.get(
        `${API}/enterprise/hackathon?hackathon_id=${h_id}`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchLeaderboardData(page, h_id, sort) {
    try {
      let sortParam = "";

      if (sort) sortParam = sort;

      const res = await axios.get(
        `${API}/enterprise/hackathon/leaderboard/${page}?hackathon_id=${h_id}&sort=${sortParam}`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
    setLeaderBoardLength(0);
    setSelectedUser(null);
    setIsLoading(true);

    setIsLeaderBoardLoading(true);

    setHackathonList(user.company.hackathons);

    fetchHackathonData(props.match.params.hackathonId)
      .then((res) => {
        setHackathonData({
          name: res.data.message.HName,
          image: res.data.message.SImage,
          registration: res.data.message.HUsers,
          leaderboard: res.data.message.leaderboard_submissions,
          end: res.data.message.HEnddate,
          start: res.data.message.HStartDate,
          href: res.data.message.SImage.split("/")[3],
          resume_count: res.data.message.resume_count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.params.hackathonId]);

  useEffect(() => {
    fetchLeaderboardData(1, props.match.params.hackathonId, sort)
      .then((res) => {
        setIsLoading(false);
        setIsLeaderBoardLoading(false);
        setLeaderBoardLength(res.data.leaderboard_length);
        setLeaderboardUsers(res.data.message);
        setSelectedUser(
          res.data.message.filter((item, index) => {
            return index === 0;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.params.hackathonId, sort]);
  async function fetchUserProfileData() {
    try {
      const res = await axios.get(
        `${API}/enterprise/hackathon/userprofile?hackathon_id=${props?.match?.params?.hackathonId}&user_id=${selectedUser[0]?.User_Oid}`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setIsUserDetailsLoading(true);
    if (selectedUser) {
      fetchUserProfileData()
        .then((res) => {
          setIsUserDetailsLoading(false);
          setUserInfo(res.data.user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedUser]);

  const getHackathonDataBySearchterm = async () => {
    if (searchTerm.length > 3) {
      setIsLeaderBoardLoading(true);
      setIsUserDetailsLoading(true);
      setIsSearch(true);
      await axios
        .get(
          `${API}/enterprise/leaderboard_search/${searchTerm}?hackathon_id=${props?.match?.params?.hackathonId}`
        )
        .then((res) => {
          if (res.data) {
            setLeaderboardUsers(res.data.data);
            setLeaderBoardLength(res.data.data.length);
            setSelectedUser(
              res.data.data.filter((item, index) => {
                return index === 0;
              })
            );
          } else {
            setLeaderBoardLength(0);
            setLeaderboardUsers([]);
            setSelectedUser([]);
            setUserInfo([]);
          }
        })
        .catch((err) => {
          setLeaderBoardLength(0);
          setLeaderboardUsers([]);
          setSelectedUser([]);
        });
      setIsLeaderBoardLoading(false);
      setIsUserDetailsLoading(false);
    } else {
      if (isSearch) {
        setLeaderBoardLength(0);
        setLeaderboardUsers([]);
        setUserInfo([]);
        setIsSearch(false);
        setIsLeaderBoardLoading(true);
        setIsUserDetailsLoading(true);
        fetchLeaderboardData(1, props.match.params.hackathonId, sort)
          .then((res) => {
            setLeaderBoardLength(res.data.leaderboard_length);
            setLeaderboardUsers(res.data.message);
            setSelectedUser(
              res.data.message.filter((item, index) => {
                return index === 0;
              })
            );
            setIsLeaderBoardLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const renderer = ({ days, hours, minutes, completed }) => {
    // Render a countdown
    if (completed) setCountdownCompleted(true);
    return (
      <div
        className={`d-flex justify-content-between align-items-center countDownValues ${
          completed ? "completed" : ""
        } `}
      >
        <div>
          <h3>{days}</h3>
          <span>DAYS</span>
        </div>
        <div>
          <h3>{hours}</h3>
          <span>HOURS</span>
        </div>
        <div>
          <h3>{minutes}</h3>
          <span>MIN</span>
        </div>
      </div>
    );
  };

  const sortByRef = useRef();

  useEffect(() => {
    const closeSalaryDropDown = (e) => {
      if (sortByRef?.current && !sortByRef?.current?.contains(e.target))
        setShowSortBy(false);
    };
    document.body.addEventListener("mousedown", closeSalaryDropDown);
  }, []);

  useEffect(() => {
    getHackathonDataBySearchterm();
  }, [searchTerm]);

  return (
    <div>
      <Header
        className="header mt-5"
        heading={`${hackathonData?.name}`}
        route={`${
          selectedAssessment === ""
            ? "/myHackathons"
            : `/myHackathons/${props.match.params.hackathonId}`
        }`}
        isLoading={isLoading}
        showAssessmentType={selectedAssessment}
        setShowAssessmentType={setSelectedAssessment}
      />

      <div className="container-fluid px-0 mt-4">
        <div className="hackathonData">
          <Navbar />
          <div
            className={`position-relative ${
              isSidebarCollapsed ? "collapsed-content-page" : "content-page"
            }`}
          >
            {selectedAssessment === "" ? (
              <div className="hackathonDataContainer pt-4">
                <div className="col-md-12 d-flex justify-content-between hackathonDataHeader mx-auto">
                  {isLoading ? (
                    <LoadingShape
                      className="me-3"
                      style={{ width: "20%", height: "60px" }}
                    />
                  ) : (
                    hackathonData?.registration > 0 && (
                      <div className="hackathonTotalRegistration cardHeading me-3">
                        <span>{hackathonData?.registration}</span>
                        Total Registration
                      </div>
                    )
                  )}

                  {isLoading ? (
                    <LoadingShape
                      className="me-3"
                      style={{ width: "20%", height: "60px" }}
                    />
                  ) : hackathonData?.leaderboard > 0 ? (
                    <div className="hackathonTotalSubmissions cardHeading me-3">
                      <span>{hackathonData?.leaderboard}</span>
                      Total Submissions
                    </div>
                  ) : (
                    <></>
                  )}
                  {isLoading ? (
                    <LoadingShape
                      className="me-3"
                      style={{ width: "50%", height: "60px" }}
                    />
                  ) : (
                    hackathonData?.end && (
                      <div
                        className={`${
                          moment(hackathonData?.end) > moment()
                            ? "hackathonScheduleActive"
                            : "hackathonScheduleInactive"
                        } align-items-center col-md-6 d-flex inline`}
                      >
                        <img
                          className="me-2"
                          src={
                            moment(hackathonData?.end) > moment()
                              ? hackathonCalendarGreen
                              : hackathonCalendarRed
                          }
                        />
                        <div className="col-md-6">
                          <div>
                            Hackathon ends on {formatDate(hackathonData?.end)}
                          </div>
                          <div className="d-flex sidebarInactiveText">
                            {hackathonData?.start && hackathonData?.end
                              ? `Duration: ${formatDateInMD(
                                  hackathonData?.start
                                )} - 
                            ${formatDateInMD(hackathonData?.end)}`
                              : ""}

                            <div className="sidebarInactiveText">
                              <div
                                className={`${
                                  moment(hackathonData?.end) > moment()
                                    ? "hackathonStatusActiveDot"
                                    : "hackathonStatusInactiveDot"
                                } mx-2`}
                              ></div>
                              {moment(hackathonData?.end) > moment()
                                ? "Active"
                                : "Expired"}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            window.open(
                              "https://machinehack.com/hackathon/" +
                                hackathonData.href +
                                "/overview",
                              "_blank"
                            );
                          }}
                          className={`${
                            moment(hackathonData?.end) > moment()
                              ? "visitHackathonBtnActive"
                              : "visitHackathonBtnInactive"
                          } ms-auto activeHackathonBtn `}
                        >
                          Visit Hackathon Page
                        </button>
                      </div>
                    )
                  )}
                </div>
                <div className="col-md-12 mt-3 d-flex hackathonDataSearch align-items-center">
                  {isLoading ? (
                    <div className="col-md-4">
                      <LoadingShape style={{ width: "40%", height: "30px" }} />
                    </div>
                  ) : leaderboardLength > 0 || !isSearch ? (
                    <span className="col-md-4 cardHeading">
                      Leaderboard ({leaderboardLength})
                    </span>
                  ) : (
                    <span className="col-md-4 cardHeading">
                      Showing search results for {searchTerm}
                    </span>
                  )}

                  <div className="text-end col-md-4">
                    {isLoading ? (
                      <LoadingShape
                        className="text-left"
                        style={{ height: "30px" }}
                      />
                    ) : (
                      <div className="hackathonDataSearchInput text-left">
                        <img className="me-2" src={hackathonSearch} />
                        <input
                          className="w-75 formInput"
                          placeholder="Search for participants"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <div className="col-md-4 pe-3 text-end">
                      <LoadingShape
                        className="ms-auto"
                        style={{ width: "20%", height: "30px" }}
                      />
                    </div>
                  ) : (
                    <div className="hackathonDataSearchFilter col-md-4 pe-3 text-end">
                      <span
                        ref={sortByRef}
                        onClick={() => setShowSortBy(!showSortBy)}
                        className="cursor-pointer"
                      >
                        Sort by{" "}
                        {sort === "Rank"
                          ? "Rank"
                          : sort === "Asc"
                          ? "A to Z"
                          : sort === "Dsc"
                          ? "Z to A"
                          : "Global Rank"}
                        {showSortBy && (
                          <div className="hackathonDataSortByFilter cursor-pointer col-md-5">
                            <span onClick={() => setSort("Rank")}>
                              <img
                                className="me-3 "
                                src={hackathonRankFilter}
                              />
                              Rank
                            </span>
                            <span onClick={() => setSort("Asc")}>
                              <img className="me-3 ms-1" src={arrowDownRed} />
                              Sort A to Z
                            </span>
                            <span onClick={() => setSort("Dsc")}>
                              <img className="me-3 ms-1" src={ArrowUp} />
                              Sort Z to A
                            </span>
                            <span onClick={() => setSort("GlobalRank")}>
                              <img className="me-2" src={globelRanking} />
                              Global Rank
                            </span>
                          </div>
                        )}
                      </span>
                      <img className="ms-2" src={dropdownArrow} />
                    </div>
                  )}
                </div>
                {leaderboardLength > 0 ||
                isLeaderBoardLoading ||
                isUserDetailsLoading ? (
                  <div className="col-md-12 d-flex">
                    <div className="col-md-4 candidatesListComponent">
                      <CandidatesList
                        leaderboardUsers={leaderboardUsers}
                        isLoading={isLeaderBoardLoading}
                        onSelect={handleSelectUser}
                        selectedUser={selectedUser}
                        activePage={activePage}
                        leaderboardLength={leaderboardLength}
                        handleOnChangePagination={handleOnChangePagination}
                      />
                    </div>
                    <UserDetails
                      selectedUserRank={selectedUser && selectedUser[0]?.Rank}
                      userInfo={userInfo}
                      selectedAssessment={selectedAssessment}
                      setSelectedAssessment={setSelectedAssessment}
                      assessmentModalData={assessmentModalData}
                      setAssessmentModalData={setAssessmentModalData}
                      isLoading={isUserDetailsLoading}
                      uId={selectedUser && selectedUser[0]?.User_Oid}
                    />
                  </div>
                ) : (
                  <div className="candidatesListNoData">
                    <img src={NoResult} />
                    <div className="candidatesListNoDataText">
                      No results found
                    </div>
                    <div className="candidatesListNoDataDec">
                      It seems we can’t find any results based on your search.
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <SelectAssessment
                type="Assessment"
                showAssessmentType={selectedAssessment}
                selectedUserEmail={userInfo.Email}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
