import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./pages/PublicPages/NewLogin/Login";
import Signup from "pages/PublicPages/SignUp/SignUp";
import SetupPassword from "./pages/PublicPages/SetupPasswordPage/SetupPassword";
import Applicants from "./pages/PrivatePages/JobsPage/JobApplicant/Applicants";
import MyHackathons from "./pages/PrivatePages/MyHackathonsPage/MyHackathons";
import Hackathon from "./pages/PrivatePages/MyHackathonsPage/Hackathon/Hackathon";
import Settings from "./pages/PrivatePages/SettingsPage/Settings";
import ErrorPage from "./pages/PublicPages/ErrorPage/ErrorPage";
import Jobs from "./pages/PrivatePages/JobsPage/Jobs";
import EditJob from "./pages/PrivatePages/JobsPage/EditJob/EditJob";
import PrivateRoute from "./helper/PrivateRoute";

// Interview Module START
import InterviewPage from "./pages/PrivatePages/Interview/InterviewPage";
import InterviewPaper from "./pages/PrivatePages/Interview/InterviewPaper";
import AllInterview from "./pages/PrivatePages/Interview/AllInterview/AllInterview";
import InterviewProcess from "./pages/PrivatePages/Interview/InterviewProcess/InterviewProcess";
import Report from "./pages/PrivatePages/Interview/Report/Report";
import NewDashBoard from "./pages/PrivatePages/DashboardPage/NewDashboard";
import AdminView from "./pages/PrivatePages/AdminView/AdminView";
import AdminRoute from "./helper/AdminRoute";
import NewAddJob from "./pages/PrivatePages/JobsPage/AddJob/NewAddJob";
import SetupOrganization from "pages/PrivatePages/SettingsPage/Sections/SetupOrganization/SetupOrganization";
import IamUserTable from "pages/PrivatePages/SettingsPage/Sections/IAM/IamUserTable";
import Assessment from "pages/PrivatePages/Assessment";
import CreateCustomMCQ from "pages/PrivatePages/Assessment/CreateCustomMCQ";
import UserTimeLine from "pages/PrivatePages/Assessment/UserTimeLine";
import UserReport from "pages/PrivatePages/Assessment/UserReport";
import QuestionSummary from "pages/PrivatePages/Assessment/QuestionSummary";
import CreateCustomCoding from "pages/PrivatePages/Assessment/CreateCustomCoding";
import Questions from "pages/PrivatePages/Assessment/Questions";
import JitsiComp from "pages/PrivatePages/Interview/components/NewJistsi";
import ApiKeys from "pages/PrivatePages/SettingsPage/Sections/ApiKeys/ApiKeys";
import UserSubscriptions from "pages/PrivatePages/SettingsPage/Sections/UserSubscriptions/UserSubscriptions";
// Interview Module END
//resume search
import ResumeSearch from "pages/PrivatePages/ResumeSearch/ResumeSearch"
import UnProtectedRoute from "helper/UnProtectedRoute";
import ListOfStudyPlan from "pages/PrivatePages/LMS/ListOfStudyPlan/ListOfStudyPlan";
import StudyPlan from "pages/PrivatePages/LMS/StudyPlan/StudyPlan";
export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <UnProtectedRoute exact path="/" component={Login} />
        <Route exact path="/jitsi" component={JitsiComp} />
        <Route exact path="/applicants" component={Applicants} />
        <UnProtectedRoute exact path="/login" component={Login} />
        <UnProtectedRoute exact path="/signup" component={Signup} />
        <Route exact path="/setPassword/:userId" component={SetupPassword} />
        <UnProtectedRoute exact path="/resetPassword/:userId/:token" component={Login} />
        <PrivateRoute exact path="/dashboard" component={NewDashBoard} />
        <PrivateRoute exact path="/lms" component={ListOfStudyPlan} />
        <PrivateRoute exact path="/lms/:studyplanSlug" component={StudyPlan} />

        {/* <PrivateRoute exact path="/new-dashboard" component={NewDashBoard} /> */}
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute
          exact
          path="/settings/editProfile"
          component={SetupOrganization}
        />
        <PrivateRoute exact path="/settings/iamUser" component={IamUserTable} />
        <PrivateRoute exact path="/settings/apikeys" component={ApiKeys} />
        <PrivateRoute exact path="/settings/my-subscriptions" component={UserSubscriptions} />

        <PrivateRoute exact path="/myHackathons" component={MyHackathons} />
        <PrivateRoute
          exact
          path="/myHackathons/:hackathonId"
          component={Hackathon}
        />
        <PrivateRoute exact path="/jobs" component={Jobs} />
        <PrivateRoute exact path="/jobs/addJob" component={NewAddJob} />
        <PrivateRoute exact path="/jobs/addJob/:jobId" component={NewAddJob} />
        <PrivateRoute exact path="/jobs/editJob/:jobId" component={NewAddJob} />
        <PrivateRoute
          exact
          path="/admin/jobs/editJob/:jobId"
          component={EditJob}
        />
        <PrivateRoute exact path="/jobs/:jobId" component={Applicants} />
        <PrivateRoute exact path="/assessments" component={Assessment} />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/:questionType/questions/:sectionId"
          component={Questions}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/:questionType/questions-set/:sectionId"
          component={Questions}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/create/mcq/:sectionId"
          component={CreateCustomMCQ}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/update/mcq/:sectionId"
          component={CreateCustomMCQ}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/create/coding/:sectionId"
          component={CreateCustomCoding}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/update/coding/:sectionId"
          component={CreateCustomCoding}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/participants"
          component={QuestionSummary}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/questions"
          component={QuestionSummary}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/setting/:settingtype"
          component={QuestionSummary}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/report/:reportId"
          component={UserReport}
        />
        <PrivateRoute
          exact
          path="/assessments/:assessmentId/timeline/:userId"
          component={UserTimeLine}
        />
        {/* Interview Module START */}
        <PrivateRoute exact path="/interviews" component={AllInterview} />
        <Route
          exact
          path="/interview-paper/:tokenId"
          component={InterviewPage}
        />
        <Route
          exact
          path="/interview-paper/:questionId/:tokenId"
          component={InterviewPaper}
        />
        <PrivateRoute
          exact
          path="/interviews/all-interviews"
          component={AllInterview}
        />
        <PrivateRoute
          exact
          path="/interviews/interview-process/:interviewId"
          component={InterviewProcess}
        />
        <PrivateRoute
          exact
          path="/interviews/interview-report/:interviewId/:interviewRoundId"
          component={Report}
        />
        {/* ?Interview Module END */}
        {/* resume search  */}
        <PrivateRoute
          exact
          path="/resumeSearch"
          component={ResumeSearch}
        />
        <AdminRoute exact path="/admin/companies" component={AdminView} />
        <AdminRoute exact path="/admin/jobs" component={AdminView} />
        <AdminRoute exact path="/admin/interviews" component={AdminView} />
        <AdminRoute exact path="/admin/assessments" component={AdminView} />
        <AdminRoute exact path="/admin/plans" component={AdminView} />
        <AdminRoute exact path="/admin/invoices" component={AdminView} />

        <Route path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}
