import React, { useState } from "react";
import hackathon_Resume from "../../../../../../assets/Icons/MyHackathons/hackathon_Resume.png";
import downloadResume from "../../../../../../assets/Icons/MyHackathons/downloadResume.svg";
import { Document, Page, pdfjs } from "react-pdf";
import "./WorkExperience.css";
import { LoadingShape } from "react-rainbow-components";

function WorkExperience(props) {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handleResume() {
    window.open(props.resume, "_blank");
  }

  return (
    <div>
      {/* <div className="hackathonWorkExperience paragraph mt-4">
        <span className="cardHeading"> Work Experience</span>
        <div className="d-flex ms-2 mt-3 hackathonWorkExperienceHeader justify-content-between">
          <div className="d-flex">
            <span className="me-2">Full Stack Developer</span>|
            <span className="ms-2">Kruxonomy Technology</span>
          </div>
          <div>March 2020 - Present</div>
        </div>
        <p className="paragraph mt-3 ms-2">
          "We are a digital & innovation studio. We collaborate to work on
          digital products, ui/ux, cloud-solutions, marketing, operations &
          others."
        </p>
        <div className="d-flex ms-2 mt-5 hackathonWorkExperienceHeader justify-content-between">
          <div className="d-flex ">
            <span className="me-2">Full Stack Developer</span>|
            <span className="ms-2">Kruxonomy Technology</span>
          </div>
          <div>March 2020 - Present</div>
        </div>
        <p className="paragraph mt-3 ms-2">
          For different businesses I need to write different sort of content.
          How do I decide what I should write? It depends on how the audience
          can High quality example sentences with “for a particular business” in
          context from reliable sources - Ludwig is the linguistic search engine
          that helps you to.
        </p>
      </div> */}
      <div className="hackathonEducation mt-4">
        {/* <span className="cardHeading">Education</span>
        <hr className="my-3" /> */}
        {/* <div className="d-flex mx-2 mt-3 currentCompanyData paragraph justify-content-between">
          <div className="d-flex">
            <span className="me-2">Full Stack Developer</span>
            <span className="hackathonDataVerticalBar"></span>
            <span className="ms-2">Kruxonomy Technology</span>
          </div>
          <div>March 2020 - Present</div>
        </div> */}
        {props?.resume && !props.isLoading && !props.isLeaderBoardLoading && (
          <div className="hackathonResumeDownload text-center mt-3">
            <button
              className="activeTab mb-3 block mx-auto"
              onClick={() => {
                handleResume();
              }}
            >
              Resume
              <img className="ms-2" src={downloadResume} />
            </button>

            <div className="userResume">
              <>
                <Document
                  file={props.resume}
                  options={{ workerSrc: "pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {/* <Page pageNumber={pageNumber} /> */}
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page,index) => (
                      <Page pageNumber={page} key={index} />
                    ))}
                </Document>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkExperience;
