import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./assessment.css";
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";
import CreateAssessment from "../../../assets/Icons/Assessment/CreateAssessment.svg";
import draftAssess from "../../../assets/Icons/Assessment/draftAssess.svg";
import starredAssess from "../../../assets/Icons/Assessment/starredAssess.svg";
import CreateCheck from "../../../assets/Icons/Assessment/CreateCheck.svg";
import AddIcon from "../../../assets/Icons/Jobs/Group1187.svg";
import assessmentListSearch from "../../../assets/Icons/Assessment/assessmentListSearch.svg";
import assessmentListArrow from "../../../assets/Icons/Assessment/assessmentListArrow.svg";
import selectRoleDownArrow from "../../../assets/Icons/Assessment/selectRoleDownArrow.svg";
import cross from "../../../assets/Icons/Assessment/cross.svg";
import TagCross from "../../../assets/Icons/Assessment/TagCross.svg";
import AddRole from "../../../assets/Icons/Assessment/AddRole.svg";
import SelectRole from "./components/SelectRole";
import QuestionSetCard from "./components/QuestionSetCard";
import plus from "../../../assets/Icons/Assessment/plus.svg";
import QuestionCard from "./components/QuestionCard";
import QuestionsFilter from "./components/QuestionsFilter";
import BuildYourAssessmentSection from "./components/BuildYourAssessmentSection";
import AssessmentTabs from "./components/AssessmentTabs";
import { LoadingShape } from "react-rainbow-components";
import axios from "axios";
import { BootcampBackend } from "backend";
import QuestionSummary from "./QuestionSummary";
import { useHistory } from "react-router";
import ToastMessages from "./components/ToastMessages";
import ConfirmationModal from "../JobsPage/components/ConfirmationModal/ConfirmationModal";
import PremiumIcon from "assets/Icons/Pricing/crown.svg";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionPlanScreen from "../PricingPages/SubscriptionPlanScreen";
import { increementAssessmentCount } from "store/reducers/subscriptionSlice";
import { decreementAssessmentCount } from "store/reducers/subscriptionSlice";
import { isAuthenticated } from "helper/user";
import LockIcon from "assets/Icons/lockIcon.svg";
import BlackCrown from "assets/Icons/block-crown.svg";
import CreateAssessmentWithWarningPopup from "./components/CreateAssessmentWithWarningPopup";
import ContactUs from "components/ContactUs/ContactUs";
import { useSidebar } from "helper/SideBarContext";

const PlaceHolderData = {
  _id: "placeholder_id",
  sections: [
    {
      _id: "1",
      mcqQuestions: [],
      practiceQuestions: [],
      duration: 0,
      score: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      title: "Section 1",
      sectionType: "MCQ",
      __v: 0,
    },
    {
      _id: "2",
      mcqQuestions: [],
      practiceQuestions: [],
      duration: 0,
      score: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      title: "Section 2",
      sectionType: "CODING",
      __v: 0,
    },
  ],
  duration: 0,
  score: 0,
  starred: false,
  publish: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  jobRole: {
    _id: "1",
    createdAt: new Date(),
    updatedAt: new Date(),
    title: "Business Analyst",
    slug: "business-analyst-bfbf9",
    __v: 0,
  },
  title: "Business Analyst 12",
  company: {
    _id: "1",
    companyName: "Company",
  },
  createdBy: {
    _id: "1",
    firstName: "Swathi",
    lastName: "S",
    emailId: "email@gmail.com",
  },
  fieldsForCandidate: [
    "Name",
    "Experience",
    "currentCompany",
    "LatestDesignation",
    "currentCtc",
    "expectingCtc",
    "noticePeriod",
    "city",
    "Resume_key",
  ],
  mandataryFields: [
    "Name",
    "currentCompany",
    "currentCtc",
    "noticePeriod",
    "Resume_key",
  ],
  testInstructions:
    "<ol><li>This is a timed test. Please make sure you are not interrupted during the test, as the timer cannot be paused once started.</li><li>Please ensure you have a stable internet connection.</li><li>We recommend you to try the sample test for a couple of minutes, before taking the main test.</li></ol>",
  testDisclaimer:
    "When enabled, this declaration will appear on the test login page along with a checkbox. Candidates will not be able to commence this test unless they check the box, expressing assent.",
  skills: [],
  praticipants: 0,
};

export default function Assessment(props) {
  const { isSidebarCollapsed } = useSidebar();
  const [isLoading, setIsLoading] = useState(true);
  const [showBuildAssessmentModal, setShowBuildAssessmentModal] =
    useState(false);
  const [showBuildAssessmentPage, setShowBuildAssessmentPage] = useState(false);
  const [selectRoleValue, setSelectRoleValue] = useState("");
  const [allAssessments, setAllAssessments] = useState([]);
  const [assessmentType, setAssessmentType] = useState("all");
  const [jobRoles, setJobRoles] = useState([]);
  const [filters, setFilters] = useState({ role: [], owner: "" });
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [jobRoleCurrentPage, setJobRoleCurrentPage] = useState(1);
  const [totalJobrole, setTotalJobrole] = useState(0);
  const [totalAssessments, setTotalAssessments] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const [publishQuestion, setPublishQuestion] = useState({});
  const [publishMOdal, setPublishMOdal] = useState({
    heading: "",
    body: "",
    actionBtnName: "",
    closeBtnName: "",
    actionBtn: () => {},
    show: false,
  });
  const [showPlan, setShowPlan] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [
    showCreateAssessmentWithWarningPopup,
    setShowCreateAssessmentWithWarningPopup,
  ] = useState(false);
  const history = useHistory();
  const subscription = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const StarterSubscriptionAssessmentLimit = 1;

  const createAssessment = () => {
    if (
      isAuthenticated()?.role === "admin_panel" ||
      subscription.totalCreatedAssessmentCount <
        (subscription.subscriptionData?.mh_subscription_plan_id
          ?.assessmentCount || StarterSubscriptionAssessmentLimit)
    ) {
      setShowBuildAssessmentModal(true);
    } else {
      if (
        subscription.totalCreatedAssessmentCount >
        (subscription.subscriptionData?.mh_subscription_plan_id
          ?.assessmentCount || StarterSubscriptionAssessmentLimit)
      ) {
        setShowCreateAssessmentWithWarningPopup(
          !showCreateAssessmentWithWarningPopup
        );
      } else {
        setShowPlan(!showPlan);
      }
    }
  };
  const handleCreateRole = async () => {
    try {
      const response = await axios.post(
        `${BootcampBackend}/enterpriseAssessment/createJobRole`,
        { title: selectRoleValue },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  const getAllJobRoles = async () => {
    try {
      const response = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getAllJobRoleEnterprise?current_page=${jobRoleCurrentPage}${
          selectRoleValue ? "?searchText=" + selectRoleValue : ""
        }`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (response && response.data.status.code !== 403) {
        setJobRoles((prev) => [...prev, ...response.data.result.data]);
        setTotalJobrole(response.data.result.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleClearFilters = () => {
    setFilters({ role: [], owner: "" });
  };
  const handleRoleFilter = (roleData) => {
    const roles = [...filters.role];
    if (roles?.length === 0) {
      roles.push(roleData);
      setFilters((prev) => ({
        ...prev,
        role: roles,
      }));
    } else if (roles?.some((item) => item._id === roleData._id)) {
      setFilters((prev) => ({
        ...prev,
        role: prev?.role?.filter((x) => x._id !== roleData._id),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        role: prev?.role?.concat(roleData),
      }));
    }
  };

  const onJobRoleSubmit = async (role) => {
    try {
      const requestBody = {
        jobRoleId: role?._id,
        jobRoleTitle: role?.title,
      };
      const sectionResponse = await axios.post(
        `${BootcampBackend}/enterpriseAssessment/createEnterpriseAssessment`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (sectionResponse) {
        history.push(
          `/assessments/${sectionResponse?.data?.result?.data?._id}/questions`
        );
        dispatch(increementAssessmentCount());
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAllAssessments = async (page_num, type) => {
    try {
      const limit = 10;
      const paramData = {
        limit: limit,
        current_page: page_num,
      };
      assessmentType === "mySelf"
        ? (paramData.createdBy = assessmentType)
        : assessmentType === "starred"
        ? (paramData.starredType = assessmentType)
        : assessmentType === "unpublished"
        ? (paramData.draft = assessmentType)
        : null;
      if (filters.role.length > 0) {
        let roleArr = [];
        filters.role.filter((x) => {
          roleArr.push(x._id);
        });
        paramData.jobRole = roleArr.toString();
      }
      filters.owner === "mytest"
        ? (paramData.createdBy = "mySelf")
        : filters.owner !== "" && (paramData.createdBy = "all");
      const response = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getAllAssessmentsUnderCompany`,
        {
          params: paramData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (response && response.data.status.code !== 403) {
        setCurrentPage(page_num);
        type
          ? setAllAssessments((prev) => [
              ...prev,
              ...response?.data?.result?.data,
            ])
          : setAllAssessments(response?.data?.result?.data);
        setTotalAssessments(response?.data?.result?.total);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleQuestionSetClick = (questionObj) => {
    if (selectedQuestions.includes(questionObj._id)) {
      let copy = [...selectedQuestions];
      let indexOfQuestion = copy.indexOf(questionObj._id);
      copy.splice(indexOfQuestion, 1);
      setSelectedQuestions(copy);
    } else {
      setSelectedQuestions([...selectedQuestions, questionObj._id]);
    }
  };
  const bookmarkAssessment = async (assessment) => {
    try {
      const requestBody = assessment;
      requestBody.starred = assessment?.starred ? false : true;
      const publishResponse = await axios.put(
        `${BootcampBackend}/enterpriseAssessment/updateEnterpriseAssessment/${assessment._id}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (publishResponse) {
        setAllAssessments((prev) =>
          prev.filter((x) => {
            if (x._id === assessment?._id) {
              x.starred = requestBody.starred;
            }
            return prev;
          })
        );
        setToastMessage({
          variant: "success",
          show: true,
          message: `${
            requestBody.starred
              ? "Assessment has been added to bookmark list successfully."
              : "Assessment has been removed from bookmark list successfully."
          }`,
          duration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;

    if (bottom) {
      totalAssessments > allAssessments.length &&
        getAllAssessments(currentPage + 1, true);
    }
  };

  const DeleteAssessments = async (data, type) => {
    try {
      let Ids = [];
      type === "single"
        ? Ids.push(data)
        : selectedQuestions.map((x) => {
            Ids.push(x);
          });
      const deleteAssessmentResponse = await axios.delete(
        `${BootcampBackend}/enterpriseAssessment/deleteAssessment`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
          data: {
            assessmentIds: Ids,
          },
        }
      );
      if (deleteAssessmentResponse) {
        if (
          isAuthenticated()?.role !== "admin_panel" &&
          subscription.totalCreatedAssessmentCount >
            (subscription.subscriptionData?.mh_subscription_plan_id
              ?.assessmentCount || StarterSubscriptionAssessmentLimit)
        ) {
          getAllAssessments(1, false);
        } else {
          setAllAssessments(
            allAssessments.filter((o1) => !Ids.some((o2) => o1._id === o2))
          );
        }
        setSelectedQuestions([]);
        setShowPublishPopup(false);
        setToastMessage({
          variant: "success",
          show: true,
          message: `${
            type === "single" ? "1" : selectedQuestions.length
          } assessment${
            type === "single" ? "" : "s"
          } has been deleted successfully.`,
          duration: 3000,
        });
        dispatch(
          decreementAssessmentCount(
            deleteAssessmentResponse?.data?.result?.data?.deletedCount
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const publishAssessment = async (assessmentData) => {
    try {
      const requestBody = assessmentData;
      requestBody.publish = assessmentData.publish ? false : true;
      const publishResponse = await axios.put(
        `${BootcampBackend}/enterpriseAssessment/updateEnterpriseAssessment/${assessmentData._id}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (publishResponse) {
        setShowPublishPopup(false);
        setAllAssessments(
          allAssessments.filter((x) => {
            if (x._id === assessmentData?._id) {
              x.publish = requestBody.publish;
            }
            return allAssessments;
          })
        );
        setToastMessage({
          variant: "success",
          show: true,
          message: `Assessment has been ${
            requestBody.publish ? "published" : "unpublished"
          } successfully`,
          duration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkRole = (data) => {
    let checked = false;
    filters?.role?.filter((x) => {
      if (x._id === data._id) {
        checked = true;
      }
    });
    return checked;
  };

  const NoMyAssessments = () => {
    return (
      <div className="BuildYourAssessment text-center">
        {assessmentType === "starred" || assessmentType === "unpublished" ? (
          assessmentType === "unpublished" ? (
            <img src={draftAssess} />
          ) : (
            <img src={starredAssess} />
          )
        ) : (
          <img src={CreateAssessment} />
        )}

        <div className="BuildYourAssessmentText mt-4">
          {allAssessments.length === 0 && filters.role.length > 0
            ? "No Assessment found for the selected Job Role"
            : assessmentType === "starred" || assessmentType === "unpublished"
            ? `Your ${
                assessmentType === "unpublished" ? "draft" : assessmentType
              } list is empty`
            : "Looks like you haven't created any test so far"}
        </div>

        <div className="mt-3 BuildYourAssessmentDescription text-center mx-auto">
          Get started on creating assessment and invite candidates to asses them
        </div>

        {allAssessments.length === 0 && filters.role.length > 0 ? (
          <></>
        ) : assessmentType === "starred" || assessmentType === "unpublished" ? (
          <></>
        ) : (
          <div className="mt-4 pt-2">
            <button
              className="BuildYourAssessmentMhBtn mx-auto d-flex algin-items-center no-wrap"
              onClick={createAssessment}
            >
              Create Assessments
              <img className="ms-2" src={AddIcon} />
            </button>
          </div>
        )}
      </div>
    );
  };
  const NoAssessmentSection = () => {
    return (
      <div className="BuildYourAssessment text-center">
        <img
          src={CreateAssessment}
          alt="create-assessment"
          className="img-fluid"
        />
        {isAuthenticated()?.role !== "admin_panel" &&
        subscription.subscriptionData?.mh_subscription_plan_id?.name !==
          "professional" ? (
          <p
            className={`premium-limitations mt-3 ${
              subscription.totalCreatedAssessmentCount <
              (subscription.subscriptionData?.mh_subscription_plan_id
                ?.assessmentCount || StarterSubscriptionAssessmentLimit)
                ? "under-limit-plan-hld"
                : "limit-crossed-plan-hld"
            }`}
          >
            <FontAwesomeIcon icon={faCircleCheck} className="close-icon me-2" />
            {subscription.totalCreatedAssessmentCount >
            (subscription.subscriptionData?.mh_subscription_plan_id
              ?.assessmentCount || StarterSubscriptionAssessmentLimit)
              ? subscription.subscriptionData?.mh_subscription_plan_id
                  ?.assessmentCount || StarterSubscriptionAssessmentLimit
              : subscription.totalCreatedAssessmentCount}
            /
            {subscription.subscriptionData?.mh_subscription_plan_id
              ?.assessmentCount || StarterSubscriptionAssessmentLimit}{" "}
            Assessment created
          </p>
        ) : (
          <></>
        )}

        <div className="BuildYourAssessmentText mt-4">
          {allAssessments.length === 0 && filters.role.length > 0
            ? "No Assessment found for the selected Job Role"
            : "Build your Assessment"}
        </div>

        <div className="mt-3 BuildYourAssessmentDescription text-center mx-auto">
          Get started on creating assessment and invite candidates to asses them
        </div>

        {allAssessments.length === 0 && filters.role.length > 0 ? (
          <></>
        ) : (
          <div className="mt-4 pt-2">
            <button
              className="BuildYourAssessmentMhBtn mx-auto d-flex algin-items-center no-wrap"
              onClick={createAssessment}
            >
              Create Assessments
              <img className="ms-2" src={AddIcon} />
            </button>
          </div>
        )}
      </div>
    );
  };
  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
    localStorage.removeItem("selectedQuestions");
    localStorage.removeItem("isEditQuestions");
  }, []);
  useEffect(() => {
    getAllJobRoles();
  }, [jobRoleCurrentPage]);
  // search job role API is not working
  // useEffect(() => {
  //   const jobRoleData = setTimeout(() => {
  //     if(selectRoleValue.length > 2){
  //       getAllJobRoles();
  //     }
  //   }, 1000);
  //   return () => clearInterval(jobRoleData);
  // }, [selectRoleValue]);
  useEffect(() => {
    getAllAssessments(1, false);
  }, [assessmentType, filters.role.length, filters.owner]);

  return (
    <>
      {!props.adminView && (
        <>
          <Header heading="Assessment" />
          <Navbar />
        </>
      )}
      <div
        className={`${!props.adminView ? "AssessmentCntr" : "ms-0"} ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        {/* {!isLoading && totalAssessments > 0 ? ( */}
        <div className=" pt-2">
          <div className="newAssessmentList align-items-center d-flex ps-4">
            <div className="col-5">
              {isLoading ? (
                <LoadingShape className="loaderForButton" />
              ) : (
                <button
                  onClick={createAssessment}
                  className="primaryBtnActive d-flex align-items-center"
                >
                  Create Assessment{" "}
                  <img className="ms-3" src={plus} alt="plus" />
                </button>
              )}
            </div>
            {/* {isLoading ? (
                <LoadingShape className="loaderForLabel" />
              ) : (
                <div className="newAssessmentListAssessmentSearch col-3">
                  <img
                    className="me-2"
                    src={assessmentListSearch}
                    alt="hackathonSearch"
                  />
                  <input
                    placeholder="Search for assessments"
                    className="assessmentListInput newAssessmentListInputContainer formInput w-75"
                  />
                </div>
              )} */}
          </div>
          <div className="d-flex mt-2">
            <div
              className={`${allAssessments.length === 0 ? "col-12" : "col-9"}`}
            >
              <div className="d-flex newAssessmentListTabs pb-2 pt-2 ps-4 mb-3 justify-content-between align-items-center">
                <div className="d-flex">
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <div
                      className={`me-3 cursor-pointer ${
                        assessmentType === "all" ? `selectedTab` : ``
                      }`}
                      onClick={() => setAssessmentType("all")}
                    >
                      All Tests
                    </div>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <div
                      className={`me-3 cursor-pointer ${
                        assessmentType === "mySelf" ? `selectedTab` : ``
                      }`}
                      onClick={() => setAssessmentType("mySelf")}
                    >
                      My Tests
                    </div>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <div
                      className={`me-3 cursor-pointer ${
                        assessmentType === "starred" ? `selectedTab` : ``
                      }`}
                      onClick={() => setAssessmentType("starred")}
                    >
                      Starred
                    </div>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <div
                      className={`me-3 cursor-pointer ${
                        assessmentType === "unpublished" ? `selectedTab` : ``
                      }`}
                      onClick={() => setAssessmentType("unpublished")}
                    >
                      Drafts
                    </div>
                  )}
                </div>

                {selectedQuestions.length > 0 && (
                  <button
                    className="AssessmentTabsTryBtn me-3 py-2 px-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPublishMOdal({
                        heading: "Delete Assessment",
                        body: "Are you sure you want to delete ?",
                        actionBtnName: "Delete",
                        closeBtnName: "Cancel",
                        actionBtn: () => {
                          DeleteAssessments(selectedQuestions, "multiple");
                        },
                        show: true,
                      });
                      setShowPublishPopup(true);
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>

              {allAssessments.length !== 0 ? (
                <div className="allAssessmentlisthld" onScroll={handleScroll}>
                  {subscription.subscriptionData?.mh_subscription_plan_id
                    ?.name !== "professional" &&
                  isAuthenticated()?.role !== "admin_panel" ? (
                    <div
                      className={`d-flex upgrade-plan-hld ${
                        subscription.totalCreatedAssessmentCount <
                        (subscription.subscriptionData?.mh_subscription_plan_id
                          ?.assessmentCount ||
                          StarterSubscriptionAssessmentLimit)
                          ? "under-limit-plan-hld"
                          : "limit-crossed-plan-hld"
                      }`}
                    >
                      <p
                        className={`limitation-content me-3 ${
                          subscription.totalCreatedAssessmentCount <
                          (subscription.subscriptionData
                            ?.mh_subscription_plan_id?.assessmentCount ||
                            StarterSubscriptionAssessmentLimit)
                            ? "under-limit"
                            : "limit-crossed"
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2 circle-check"
                        />
                        {subscription.totalCreatedAssessmentCount >
                        (subscription.subscriptionData?.mh_subscription_plan_id
                          ?.assessmentCount ||
                          StarterSubscriptionAssessmentLimit)
                          ? subscription.subscriptionData
                              ?.mh_subscription_plan_id?.assessmentCount ||
                            StarterSubscriptionAssessmentLimit
                          : subscription.totalCreatedAssessmentCount}
                        /
                        {subscription.subscriptionData?.mh_subscription_plan_id
                          ?.assessmentCount ||
                          StarterSubscriptionAssessmentLimit}{" "}
                        Assessment created
                      </p>
                      <button
                        className="upgrade-btn"
                        onClick={() => setShowPlan(!showPlan)}
                      >
                        <img
                          src={PremiumIcon}
                          alt="premium"
                          className="img-fluid me-2"
                        />
                        Upgrade to Professional
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {allAssessments?.map((assessment, i) => (
                    <QuestionSetCard
                      questionData={assessment}
                      index={i}
                      key={i}
                      type="assessments"
                      handleQuestionClick={handleQuestionSetClick}
                      isSidebarCollapse={isSidebarCollapsed}
                      bookmarkAssessment={() => bookmarkAssessment(assessment)}
                      DeleteAssessments={DeleteAssessments}
                      selectedQuestion={selectedQuestions}
                      publishAssessment={publishAssessment}
                      setShowPublishPopup={setShowPublishPopup}
                      showPublishPopup={showPublishPopup}
                      setPublishQuestion={setPublishQuestion}
                      publishQuestion={publishQuestion}
                      setPublishMOdal={setPublishMOdal}
                      published={assessment.publish}
                      isSubscribed={
                        subscription.subscriptionData?.mh_subscription_plan_id
                          ?.name === "basic"
                      }
                    />
                  ))}
                  {isAuthenticated()?.role !== "admin_panel" &&
                  subscription.totalCreatedAssessmentCount >
                    (subscription.subscriptionData?.mh_subscription_plan_id
                      ?.assessmentCount ||
                      StarterSubscriptionAssessmentLimit) ? (
                    <div className="position-relative masking-data-hld">
                      {Array.from({
                        length: subscription.totalCreatedAssessmentCount,
                      }).map((_, i) => (
                        <QuestionSetCard
                          questionData={PlaceHolderData}
                          index={i}
                          key={"key" + i}
                          type="placeholder-assessments"
                          handleQuestionClick={() => {}}
                          isSidebarCollapse={isSidebarCollapsed}
                          bookmarkAssessment={() => {}}
                          DeleteAssessments={() => {}}
                          selectedQuestion={selectedQuestions}
                          publishAssessment={() => {}}
                          setShowPublishPopup={() => {}}
                          showPublishPopup={showPublishPopup}
                          setPublishQuestion={() => {}}
                          publishQuestion={publishQuestion}
                          setPublishMOdal={() => {}}
                          published={true}
                          isSubscribed={
                            subscription.subscriptionData
                              ?.mh_subscription_plan_id?.name === "basic"
                          }
                        />
                      ))}
                      <div className="mask-data">
                        <div className="mask-data-upgrade-hld pe-0">
                          <img
                            src={LockIcon}
                            alt="locked"
                            className="img-fluid"
                          />
                          <p className="mx-4">
                            It appears that your plan has been downgraded, and
                            there's been a change <br />
                            in the access limit for assessments.
                          </p>
                          <button
                            className="d-flex align-items-center Upgrade-btn"
                            onClick={() => setShowPlan(!showPlan)}
                          >
                            <img
                              src={BlackCrown}
                              alt="premium"
                              className="img-fluid me-2"
                            />{" "}
                            Upgrade
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <>
                  {assessmentType === "mySelf" ||
                  assessmentType === "starred" ||
                  assessmentType === "unpublished" ? (
                    <NoMyAssessments />
                  ) : (
                    <>{!isLoading ? <NoAssessmentSection /> : <></>}</>
                  )}
                </>
              )}
            </div>

            {allAssessments.length > 0 && (
              <div className="col-3 newAssessmentListFilters">
                <div className="text-end m-3">
                  {isLoading ? (
                    <LoadingShape className="loaderForButton" />
                  ) : (
                    <button
                      className="newAssessmentListFiltersButton"
                      onClick={handleClearFilters}
                    >
                      Clear all <img className="ms-2" src={cross} />
                    </button>
                  )}
                </div>

                {filters.role.length > 0 && (
                  <>
                    <label className="newAssessmentListRole mx-3 mb-2 ">
                      Role
                    </label>
                    <div className="d-flex flex-wrap m-2">
                      {filters.role?.map((role, index) => (
                        <div
                          className="CategoryQuestionListTag me-2"
                          key={index}
                          onClick={() => handleRoleFilter(role)}
                        >
                          {role.title}
                          <img
                            className="ms-2 img-fluid"
                            src={TagCross}
                            loading="lazy"
                            alt="close"
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {filters.owner && (
                  <>
                    <label className="newAssessmentListRole mx-3 mb-2">
                      Owner
                    </label>
                    <div className="d-flex flex-wrap m-2">
                      {filters.owner && (
                        <div
                          className="CategoryQuestionListTag me-2"
                          onClick={() => setFilters({ ...filters, owner: "" })}
                        >
                          {filters.owner === "mytest"
                            ? `my test`
                            : `filter by owner`}
                          <img className="ms-2" src={TagCross} />
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="m-3 AssessmentListRadioBtn">
                  {isLoading ? (
                    <LoadingShape className="loaderForLoader" />
                  ) : (
                    <label className="newAssessmentListRole mb-3">Owner</label>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForDescription" />
                  ) : (
                    <>
                      <div className="d-flex align-items-center mt-2 mb-2">
                        <input
                          type="radio"
                          className="AssessmentListRadioBtn"
                          checked={filters.owner === "mytest"}
                          onChange={() =>
                            setFilters({ ...filters, owner: "mytest" })
                          }
                        />{" "}
                        <span className="newAssessmentFilterRadio">
                          My tests
                        </span>
                      </div>
                      <div className="d-flex align-items-center mt-2 mb-2">
                        <input
                          type="radio"
                          className=""
                          checked={filters.owner === "filterByOwner"}
                          onChange={() =>
                            setFilters({ ...filters, owner: "filterByOwner" })
                          }
                        />{" "}
                        <span className="newAssessmentFilterRadio">
                          Filter by owner(s)
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="m-3">
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <label className="newAssessmentListRole mb-3">Role</label>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForDescription" />
                  ) : (
                    jobRoles?.map((roleObj, index) => (
                      <div
                        className="d-flex align-items-center mb-2"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className=" newAssessmentListCustomCheckBox"
                          checked={checkRole(roleObj)}
                          onChange={() => handleRoleFilter(roleObj)}
                        />{" "}
                        <span className="newAssessmentFilterRadio">
                          {roleObj.title}
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ) : (
           <NoAssessmentSection />
        )} */}
        {showBuildAssessmentModal && (
          <SelectRole
            setShowBuildAssessmentPage={setShowBuildAssessmentPage}
            setShowBuildAssessmentModal={setShowBuildAssessmentModal}
            onJobRoleSubmit={onJobRoleSubmit}
            jobRoles={jobRoles}
            setJobRoles={setJobRoles}
            totalJobrole={totalJobrole}
            setJobRoleCurrentPage={setJobRoleCurrentPage}
            jobRoleCurrentPage={jobRoleCurrentPage}
            selectRoleValue={selectRoleValue}
            setSelectRoleValue={setSelectRoleValue}
          />
        )}
        {toastMessage.show && (
          <ToastMessages
            toastMessage={toastMessage}
            setToastMessage={setToastMessage}
          />
        )}
      </div>
      <ConfirmationModal
        ModalHeading={publishMOdal.heading}
        ModalBody={publishMOdal.body}
        closeIcon={true}
        ActionBtnName={publishMOdal.actionBtnName}
        closeBtnName={publishMOdal.closeBtnName}
        ActionBtn={publishMOdal.actionBtn}
        showPopup={showPublishPopup}
        setShowPopup={setShowPublishPopup}
      />
      <SubscriptionPlanScreen
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        titleType="feature"
      />
      <CreateAssessmentWithWarningPopup
        showCreateAssessmentWithWarningPopup={
          showCreateAssessmentWithWarningPopup
        }
        setShowCreateAssessmentWithWarningPopup={
          setShowCreateAssessmentWithWarningPopup
        }
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        setShowBuildAssessmentModal={setShowBuildAssessmentModal}
      />
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type="pricing"
      />
    </>
  );
}
