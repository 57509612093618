import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import leaderBoard from "assets/Icons/DashboardNew/leaderBoard.svg";
Chart.register(...registerables);
const ParticipantChart = ({ donutSeries, donutLabel }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    Chart.defaults.elements.arc.borderWidth = 0;
    Chart.defaults.datasets.doughnut.cutout = "65%";

    const chartInstance = new Chart(chartRef.current, {
      type: "doughnut",
      data: {
        labels: donutLabel ? [...donutLabel] : [],
        datasets: [
          {
            label: "",
            data: donutSeries,
            backgroundColor: [
              "#4F3FF0",
              "#DF3670",
              "#FFD378",
              "#F8DAED",
              "#FB8669",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: "left",
            labels: {
              fontColor: "black",
              fontSize: 16,
              boxWidth: 20,
              padding: 35,
            },
          },
        },
        tooltips: {
          enabled: false,
        },
      },
      plugins: [
        {
          afterUpdate: function (chart) {
            const arcs = chart.getDatasetMeta(0).data;

            arcs.forEach(function (arc) {
              arc.round = {
                x: (chart.chartArea.left + chart.chartArea.right) / 2,
                y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                radius: (arc.outerRadius + arc.innerRadius) / 2,
                thickness: (arc.outerRadius - arc.innerRadius) / 2,
                backgroundColor: arc.options.backgroundColor,
              };
            });
          },
          afterDraw: (chart) => {
            const { ctx } = chart;

            chart.getDatasetMeta(0).data.forEach((arc) => {
              const startAngle = Math.PI / 2 - arc.startAngle;
              const endAngle = Math.PI / 2 - arc.endAngle;

              ctx.save();
              ctx.translate(arc.round.x, arc.round.y);
              ctx.fillStyle = arc.options.backgroundColor;
              ctx.beginPath();
              ctx.arc(
                arc.round.radius * Math.sin(endAngle),
                arc.round.radius * Math.cos(endAngle),
                arc.round.thickness,
                0,
                2 * Math.PI
              );
              ctx.closePath();
              ctx.fill();
              ctx.restore();
            });
          },
        },
      ],
    });
  }, []);

  return (
    <div className="position-relative">
      <div className="leaderboardImgHld"><img className="leaderboardImg" src={leaderBoard} alt="leaderboard" /></div>
      <canvas ref={chartRef} id="chartJSContainer" />
    </div>
  )
};

export default ParticipantChart;
