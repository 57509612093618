import React from "react";

//CSS
import "./participants.css";
import { LoadingShape } from "react-rainbow-components";
import ParticipantChart from "./ParticipantChart";

const locationProgressColors = [
  { dark: "#4F3FF0", light: "#E9E7FD" },
  { dark: "#34C471", light: "#C8F7DC" },
  { dark: "#DF3670", light: "#FFD3E2" },
  { dark: "#00CCCA", light: "rgba(0, 204, 202, 0.2)" },
  { dark: "#FEA462", light: "rgba(254, 164, 98, 0.2)" },
];
export default function Participants({
  expeirenceData,
  locationData,
  isLoading,
}) {
  const donutSeries = expeirenceData?.map((data) => {
    return data?.Percentage;
  });

  const donutLabel = expeirenceData?.map((data) => {
    return data?.Experience + " Years";
  });
  const donutOptions = {
    colors: ["#FE627C", "#FFD14D", "#00A6FB", "#01CCCA", "#EEEEEE"],
    chart: {
      width: "100%",
      type: "donut",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "75%",
        },
      },
      bar: {
        borderRadius: 50,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: donutLabel ? [...donutLabel] : [],
    legend: {
      position: "left",
    },
  };

  return (
    <>
      {expeirenceData?.length > 0 && locationData?.length > 0 ? (
        <>
          {isLoading && locationData && expeirenceData ? (
            <div className="row m-5">
              <div className={`col-lg-12 mt-1`}>
                <LoadingShape style={{ height: "40px" }} />
              </div>
              <div className="col-lg-12 mt-4">
                <LoadingShape style={{ height: "110px" }} />
              </div>
            </div>
          ) : (
            locationData?.length > 0 &&
            expeirenceData?.length > 0 && (
              <>
                <div className="d-flex">
                  <div className="dashboardHackthonRightItem ">
                    <h5 className="dashboardSubHeadText">
                      Participants by Experience
                    </h5>
                  </div>
                  <div className="dashboardHackthonRightItem py-4">
                    <h5 className="dashboardSubHeadText">
                      Participants by Location
                    </h5>
                  </div>
                </div>
                <div className="d-flex participantsCharts justify-content-between">
                  <div className="pieChartForParticipants d-flex align-items-center justify-content-center py-2">
                    {donutSeries && (
                      <ParticipantChart
                        donutLabel={donutLabel}
                        donutSeries={donutSeries}
                      />
                    )}
                  </div>
                  <div className="pieChartForParticipants py-2">
                    {locationData.map((locationObject, index) => (
                      <div key={`location${index}`} className="row pb-4 mx-0">
                        <div className="d-flex justify-content-between mb-2 px-0">
                          <p className="locationLabelText mb-0">
                            {locationObject.Location}
                          </p>
                          <p className="locationPercentageText mb-0">
                            {locationObject.Percentage}%
                          </p>
                        </div>
                        <div
                          className="locationProgressBarHld"
                          style={{
                            background: locationProgressColors[index].light,
                            height: "3px",
                          }}
                        >
                          <div
                            className="locationProgressBar"
                            style={{
                              background: locationProgressColors[index].dark,
                              width: `${locationObject.Percentage}%`,
                              height: "100%",
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
