//CSS
import "./hackathonStatistics.css";

//TILES
import React from "react";
import { LoadingShape } from "react-rainbow-components";
import NoParticipants from "./NoParticipants";
import Participants from "./Participants";

export default function HackathonStatistics({
  hackathons,
  isLoading,
  expeirenceData,
  locationData,
}) {


  return (
    <div className="col-xl-8 col-lg-8">
      <div className="dashboardCard dashboardParticipantsItem">
        {Object.keys(hackathons).length > 0 ? (
          <>
            {isLoading && hackathons ? (
              <div className="row m-5">
                <div className={`col-lg-12 mt-1`}>
                  <LoadingShape style={{ height: "40px" }} />
                </div>
                <div className="col-lg-12 mt-4">
                  <LoadingShape style={{ height: "110px" }} />
                </div>
              </div>
            ) : (
              <>
                {" "}
                <div className="d-flex justify-content-between dashboardHeadingHld align-items-center">
                  <h5 className="dashboardHeading">Hackathon Statistics</h5>
                </div>
                {hackathons && (
                  <>
                    <div className="row bottomHackthon mx-0">
                      <div className="dashboardLeftHackthonBottomItem col ">
                        <p className="dashboardInterviewText dashboardInterviewCardMargin">
                          Total Hackathons
                        </p>
                        <h5>{hackathons?.total_hackathons_hosted}</h5>
                      </div>

                      <div className="dashboardLeftHackthonBottomItem col">
                        <p className="dashboardInterviewText dashboardInterviewCardMargin">
                          Total Registration
                        </p>
                        <h5>{hackathons?.total_participants}</h5>
                      </div>

                      <div className="dashboardLeftHackthonBottomItem col">
                        <p className="dashboardInterviewText dashboardInterviewCardMargin">
                          Total Participants
                        </p>
                        <h5>{hackathons?.total_submissions}</h5>
                      </div>

                      <div className="dashboardLeftHackthonBottomItem col">
                        <p className="dashboardInterviewText dashboardInterviewCardMargin">
                          Active Hackathons
                        </p>
                        <h5>{hackathons?.total_active_hackathons}</h5>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
        {expeirenceData?.length !== 0 && locationData?.length !== 0 ? (
          <Participants
            expeirenceData={expeirenceData}
            locationData={locationData}
            isLoading={isLoading}
          />
        ) : (
          <NoParticipants />
        )}
      </div>
    </div>
  );
}
