import React from "react";
import "./yourHackthons.css";
import dropdownArrow from "assets/Icons/DashboardNew/dropdownArrow.svg";
import { LoadingShape } from "react-rainbow-components";
import ploygon from "assets/Icons/DashboardNew/polygon.svg"
import axios from "axios";
import { API } from "../../../../backend";
import leaderBoardIcon from "assets/Icons/DashboardNew/leader.svg"
import { useState, useEffect } from "react";
const moment = require("moment");
import { useHistory } from "react-router-dom";

function YourHackthons({ yourHackathons, isLoading }) {
  const history = useHistory();
  var takeSelectedHackthon = yourHackathons[0];
  const [selectedhackathon, setSelectedhackathon] = useState(null);
   function showWinners(hackathonId) {
   if(selectedhackathon===hackathonId){
    setSelectedhackathon(null);
   }
   else{
    setSelectedhackathon(hackathonId);
   }
  }

  const countDownTimer = (newTimer) => {
    const currentDate = moment(new Date());
    let timeDiff = moment.duration(currentDate.diff(moment(newTimer)));

    let todayDate = moment(currentDate).format("L");
    let pastDate = moment(newTimer).format("L");

    let totalTime =
      timeDiff._data.hours + " hrs" + " " + timeDiff._data.minutes + " min";

    if (todayDate === pastDate) {
      return totalTime;
    } else {
      return false;
    }

    // var countDownDate = new Date(newTimer).getTime();

    // var now = new Date().getTime();

    // var distance = countDownDate - now;

    // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    // var hours = Math.floor(
    //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    // );
    // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // var result = days + "d " + hours + "h " + minutes + "m ";
    // if (days < 0 && hours < 0) {
    //   return "0 hrs 0 min";
    // }
    // return result;
  };
  useEffect(() => {
    setSelectedhackathon({ ...takeSelectedHackthon });
  }, [yourHackathons]);

  return (
    <>
      {yourHackathons.length > 0 ? (
        <div className="col-xl-4 col-lg-4">
          {isLoading && yourHackathons ? (
            <div className="row m-5">
              <div className={`col-lg-12 mt-1`}>
                <LoadingShape style={{ height: "40px" }} />
              </div>
              <div className="col-lg-12 mt-4">
                <LoadingShape style={{ height: "110px" }} />
              </div>
            </div>
          ) : (
            yourHackathons?.length > 0 && (
              <div className="hackthons dashboardHackthonsCard ">
                <div className="dashboardHackathonHeading d-flex justify-content-between hackthonsTop">
                  <h5 className="dashboardHeading">
                    Your Hackathons ({yourHackathons.length})
                  </h5>
                  <div
                    className="d-flex align-items-center viewAll"
                    onClick={() => {
                      history.push("/myHackathons");
                    }}
                  >
                    <p className="dashboardViewAll">View all</p>
                  </div>
                </div>

                <div className="allHackthons">
                  {yourHackathons.length > 0 &&
                    yourHackathons?.map((hackathon, index) => (
                      <div
                        key={`${hackathon?.hackathon_id}${index}`}
                        className="hackathonDataCard"
                      >
                        <div className="d-flex"  onClick={() => {
                          showWinners(hackathon.hackathon_id);
                        }}>
                          {" "}
                          <div>
                          <p className="dashboardSubHeadText">
                            {hackathon?.HName}
                          </p>
                          </div>
                          <img
                            src={dropdownArrow}
                            alt=">"
                            className="cursor-pointer"
                          />
                        </div>
                        <div className="hackthonDate d-flex align-items-center justify-content-between">
                            <p className="timeTabHackathon">
                              {moment(
                                hackathon?.HStartDate,
                                "DD/MM/YYYY HH:mm:ss"
                              ).format("MMM Do YY")}{" "}
                              -{" "}
                              {moment(
                                hackathon?.HEndDate,
                                "DD/MM/YYYY HH:mm:ss"
                              ).format("MMM Do YY")}
                            </p>
                          <div className="circles-gallery">
                            <img
                              src="http://placeimg.com/300/300/tech"
                              alt="Some Tech Picture"
                            />
                            <img
                              src="http://placeimg.com/400/400/tech"
                              alt="Some Tech Picture"
                            />
                            <img
                              src="http://placeimg.com/500/500/tech"
                              alt="Some Tech Picture"
                            />
                            
                          </div>
                        </div>
                        <div className="progressBar">
                          <div
                            className="insideProgress"
                            style={{ width: "50%" }}
                          ></div>
                        </div>
                        {hackathon.top_3_rankers&&selectedhackathon===hackathon.hackathon_id&&<div className="hacakthonInnerCard">
                          <div className="d-flex justify-content-center pb-3 align-items-center">
                            <p className="topRanketText pe-3 borderRight">Registration <span className="topRanketTextBold ms-2">{hackathon?.registration}</span> </p> <p className="topRanketText ps-3">Submission <span className="topRanketTextBold ms-2">480</span> </p>
                          </div>
                          <div className="d-flex justify-content-center text-center align-items-center pb-3 px-4">
                            <p className="hackathonDivider"></p>
                            <p className="topRanketText mx-4">Top Rankers </p>
                            <p className="hackathonDivider"></p>
                          </div>
                          <div className="text-center d-flex justify-content-center pb-4">
                            {hackathon.top_3_rankers.map((user,i)=> <div className="position-relative me-4"> <img src="http://placeimg.com/300/300/tech" className="winnerProfile"/> <img className="winnerPloygon" src={ploygon}/><p className="winnerPloygonText">{i+1}</p></div>)}
                          </div>
                          <div className="text-center"><button className="viewLeaderboardBtn"><img className="me-2" src={leaderBoardIcon} /> VIEW LEADERBOARD</button></div>
                        </div>}
                      </div>
                    ))}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default YourHackthons;
