import React, { useState, useEffect } from "react";
import { BootcampBackend, Compiler } from "../../../../backend";
import "./mocktest.css";

import WhiteboardWhite from "../../../../assets/Icons/Interview/White-board-White.svg";
import AssessmentWhite from "../../../../assets/Icons/Interview/Attach-Assessment-White-icon.svg";
import DiagramActiveIcon from "../../../../assets/Icons/Interview/Diagram-White.svg";
import PythonCodingWhite from "../../../../assets/Icons/Interview/Python-Coding-White.svg";
import correctIcon from "../../../../assets/Icons/Interview/New/correctIcon.svg";
import wrongIcon from "../../../../assets/Icons/Interview/New/wrongIcon.svg";
import userSelection from "../../../../assets/Icons/Interview/New/userSelection.svg";
import unattendedIcon from "../../../../assets/Icons/Interview/New/unattended.svg";
import rightWhiteArrow from "../../../../assets/Icons/Interview/New/rightWhiteArrow.svg";
import unattendedQ from "../../../../assets/Icons/Interview/New/unattendedQ.svg";
import correctQ from "../../../../assets/Icons/Interview/New/correctQ.svg";
import wrongQ from "../../../../assets/Icons/Interview/New/wrongQ.svg";

import { UnControlled as CodeMirrorEditor } from "react-codemirror2";
import "../EditorAddons";
import axios from "axios";
import { isAuthenticated } from "../../../../helper/user";
import { socket } from "../../../../socket/Socket";

const QuestionService = {
  GetAllQuestions: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BootcampBackend}/mock_question/`)
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  FetchQuestion: (questionSlug) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BootcampBackend}/mock_question/${questionSlug}`)
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  FetchAllQuestionsOfAQuestionSet: (questionSetSlug, page_number) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BootcampBackend}/mock_question/${questionSetSlug}/fetch`, {
          params: {
            current_page: page_number,
          },
        })
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  CompileCode: (sourceCode) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Compiler}/submissions/?base64_encoded=true&wait=false`, {
          source_code: btoa(sourceCode),
          language_id: 10,
          stdin: btoa(""),
        })
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  CompileCodeAndFetchSubmisssionResponse: (sourceCode, stdins) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Compiler}/submissions/?base64_encoded=true&wait=false`, {
          source_code: btoa(sourceCode),
          language_id: 10,
          stdin: btoa(stdins),
        })
        .then(async (TokenResponse) => {
          let compilationSatatus = null;
          let compilationResult = {};
          if (TokenResponse && TokenResponse.data) {
            compilationSatatus = "In Queue";
            compilationResult = null;

            while (
              compilationSatatus &&
              (compilationSatatus == "In Queue" ||
                compilationSatatus == "Processing")
            ) {
              const OutputResponse = await axios.get(
                `${Compiler}/submissions/${TokenResponse.data.token}/?base64_encoded=false&wait=false`
              );
              if (OutputResponse && OutputResponse.data) {
                compilationSatatus = OutputResponse.data.status.description;
                compilationResult = OutputResponse.data;
              } else {
                return reject("Exception in compilation");
              }
            }

            if (compilationSatatus === "Accepted") {
              return resolve(compilationResult);
            } else if (
              compilationSatatus !== "Accepted" &&
              compilationResult.stderr
            ) {
              return resolve(compilationResult);
            }
          } else {
            return reject("Exception in compilation");
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};

export default function MockTest(props) {
  const [selectedValue, setSelectedValue] = useState("a");
  const [questionSetData, setQuestionSetData] = useState({});
  const [questionSet, setQuestionSet] = useState([]);
  const [questionSetResponse, setQuestionSetResponse] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [statusAfterCompile, setStatusAfterCompile] = useState();
  const [errorStackAfterCompiling, setErrorStackAfterCompiling] = useState();
  const [intervalStart, setIntervalStart] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [result, setResult] = useState({});
  const [showresult, setShowresult] = useState(true);
  let [answeredQuestions, setAnsweredQuestions] = useState(0);

  useEffect(() => {
    getQuestionSet();
    return () => {
      setSelectedValue("");
      setQuestionSetData({});
      setQuestionSet([]);
      setCurrentQuestion({});
      setCurrentQuestionNo(1);
      setIsLoading(false);
      setStatusAfterCompile(null);
      setErrorStackAfterCompiling(null);
      setIntervalStart(false);
      setUserCode("");
      setAnsweredQuestions(0);
    };
  }, [props.assessmentSlugRef, props.assessmentdata]);

  useEffect(() => {
    const interval = setInterval(() => {}, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [intervalStart]);

  const getQuestionSet = () => {
    const url =
      props.tabData?.tabType === "enterpriseAssessment"
        ? `${BootcampBackend}/enterpriseAssessment/getAllEnterpriseAssessmentQuestions/${props.assessmentSlugRef}`
        : `${BootcampBackend}/interview/startTheQuizGetQuestions/${props.assessmentSlugRef}`;
    axios
      .get(url)
      .then((response) => {
        if (response) {
          setQuestionSetData(response.data.result.questionSetData);
          props.tabData?.tabType === "enterpriseAssessment" ? setQuestionSet(response.data.result.data) : setQuestionSet(response.data.result.questions);
          props.tabData?.tabType === "enterpriseAssessment" ? setQuestionSetResponse(response.data.result.data) : setQuestionSetResponse(response.data.result.questions);
          props.tabData?.tabType === "enterpriseAssessment" ? setCurrentQuestion(response.data.result.data[0]) : setCurrentQuestion(response.data.result.questions[0]);
          setIsLoading(false);
          setIntervalStart(true);
          if (props.assessmentdata) {
            const questions = props.tabData?.tabType === "enterpriseAssessment" ? response.data.result.data : response.data.result.questions;
            const dataaa = questions.filter((question) => {
              props.assessmentdata.filter((tabAns) => {
                if (question._id === tabAns._id) {
                  question.correctlyAnswered = tabAns.correctlyAnswered;
                  question.options = tabAns.options;
                }
              });
              return questions;
            });
            setQuestionSet(dataaa);
            setQuestionSetResponse(dataaa);
            setCurrentQuestion(dataaa[0]);
          }
        }
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

  const submitQuestionResponse = (userState) => {
    let body = {
      userState: userState,
    };
    body.interviewToken = props.interviewData.interviewToken;
    body.tabID = props.tabData.tabID;
    axios
      .post(
        `${BootcampBackend}/interview/${props.tabData?.tabType === "enterpriseAssessment" ? "evaluateSubmissionEnterpriseAssessmentInterview" :"evaluateSubmissionInterview"}/${questionSetData._id}`,
        body
      )
      .then((response) => {
        response.data.result.data.interviewToken = props.interviewToken;
        response.data.result.data.tabID = props.tabData.tabID;
        response.data.result.data.userState = userState;
        response.data.result.data.interviewRoundData =
          response.data.result.interviewRoundData;
        const responseData = {
          result: response.data.result.data,
          type: "assessment",
        };
        socket.emit("sync_report", responseData);
        const assessmentSubmissionData = {
          assessmentSubmissionData: response.data.result.data,
        };
        const tabData = {
          ...assessmentSubmissionData,
          ...props.tabData,
          ...userState,
        };
        props.tabsChange(tabData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Err: ", err);
      });
  };

  const markVisited = () => {
    let index = questionSet.findIndex((el) => el._id === currentQuestion._id);
    questionSet[index].isVisited = true;
  };

  const goToNextQuestion = () => {
    setCurrentQuestionNo(currentQuestionNo + 1);
    let indexOfSelectedQuestion = questionSetResponse?.findIndex(
      (el) => el._id === currentQuestion._id
    );
    props.interviewData.interviewRoundId.companyId !== isAuthenticated().eid &&
      markVisited();
    questionSetResponse[indexOfSelectedQuestion + 1].quesNo =
      currentQuestionNo + 1;
    questionSetResponse[indexOfSelectedQuestion + 1].interviewToken =
      props.interviewToken;
    const questionSetData = {
      result: questionSetResponse[indexOfSelectedQuestion + 1],
      type: "assessment",
    };
    props.interviewData.interviewRoundId.companyId !== isAuthenticated().eid &&
      socket.emit("sync_assessment", questionSetData);
    setCurrentQuestion(questionSetResponse[indexOfSelectedQuestion + 1]);
    if (questionSet[indexOfSelectedQuestion].type === "MCQ") {
      questionSet[indexOfSelectedQuestion].options.selected;
      setSelectedValue(questionSet[indexOfSelectedQuestion].options.option);
    } else {
      setSelectedValue(questionSet[indexOfSelectedQuestion].code_response);
    }
  };

  const goToPrevQuestion = () => {
    setCurrentQuestionNo(currentQuestionNo - 1);
    let indexOfSelectedQuestion = questionSetResponse.findIndex(
      (el) => el._id === currentQuestion._id
    );
    props.interviewData.interviewRoundId.companyId !== isAuthenticated().eid &&
      markVisited();
    questionSetResponse[indexOfSelectedQuestion - 1].quesNo =
      currentQuestionNo - 1;
    questionSetResponse[indexOfSelectedQuestion - 1].interviewToken =
      props.interviewToken;
    const questionSetResponseData = {
      result: questionSetResponse[indexOfSelectedQuestion - 1],
      type: "assessment",
    };
    props.interviewData.interviewRoundId.companyId !== isAuthenticated().eid &&
      socket.emit("sync_assessment", questionSetResponseData);
    setCurrentQuestion(questionSetResponse[indexOfSelectedQuestion - 1]);
    if (questionSet[indexOfSelectedQuestion].type === "MCQ") {
      questionSet[indexOfSelectedQuestion].options.selected;
      setSelectedValue(questionSet[indexOfSelectedQuestion].options.option);
    } else {
      setSelectedValue(questionSet[indexOfSelectedQuestion].code_response);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    let indexOf = questionSetResponse.findIndex(
      (el) => el._id === currentQuestion._id
    );
    let UIResponse = questionSet;
    let questionResponse = questionSetResponse;
    questionResponse[indexOf].options.filter((x) => {
      if (x.option === event.target.value) {
        x["selected"] = true;
        UIResponse[indexOf].isAnswered = true;
        UIResponse[indexOf].isVisited = true;
      } else {
        x["selected"] = false;
      }
    });

    setQuestionSetResponse(questionResponse);
    setQuestionSet(UIResponse);

    saveInterviewAssessmentState();
  };

  const CompileCode = async (userCode) => {
    try {
      const stdInputs = "nn";
      const questionsResponse =
        await QuestionService.CompileCodeAndFetchSubmisssionResponse(
          userCode,
          stdInputs
        );
      if (questionsResponse.stderr && questionsResponse.stderr !== null) {
        let errorStack = errorStackAfterCompiling;
        errorStack = questionsResponse.stderr
          .split("\n")
          .join()
          .replaceAll(",", "")
          .split();
        setStatusAfterCompile(
          {
            status: "error",
            message: errorStack,
          },
          setErrorStackAfterCompiling(errorStack),
          () => {
            console.log("Status::::: ", statusAfterCompile);
          }
        );
      } else {
        {
          let complierResponse = questionSetResponse;
          let indexOf = questionSetResponse.findIndex(
            (el) => el._id === currentQuestion._id
          );
          complierResponse[indexOf].coding.code = userCode;
          complierResponse[indexOf].coding.userOut =
            questionsResponse.stdout.trim();
          questionSet[indexOf].isAnswered = true;
          if (
            complierResponse[indexOf].coding.stdout ===
            questionsResponse.stdout.trim()
          ) {
            complierResponse[indexOf].coding.answerdCorrectly = true;
          } else {
            complierResponse[indexOf].coding.answerdCorrectly = false;
          }
          setQuestionSetResponse(complierResponse);
        }
        setStatusAfterCompile({
          statusAfterCompile: {
            status: "success",
            message:
              currentQuestion.coding.stdout === questionsResponse.stdout.trim()
                ? "Success, Required Output Macthed !"
                : `Required output not matched with output "${questionsResponse.stdout}"`,
          },
          errorStackAfterCompiling: [],
        });
      }
      saveInterviewAssessmentState();
    } catch (error) {
      console.log("error:: ", error);
    }
  };

  const countAnswered = () => {
    for (var i = 0; i < questionSetResponse.length; i++) {
      if (questionSetResponse[i].isAnswered)
        setAnsweredQuestions((answeredQuestions += 1));
    }
  };

  const saveInterviewAssessmentState = () => {
    let userState = questionSetResponse;
    let body = {
      assessmentUserState: userState,
      tabID: props.tabData.tabID,
      currentQuestion: currentQuestion,
    };
    axios
      .put(
        `${BootcampBackend}/interview/saveInterviewAssessmentState/${props.interviewData.interviewToken}`,
        body
      )
      .then((response) => {
        const userAnswerState = {
          assessmentUserState: response.data.result.data,
        };
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Err: ", err);
      });
  };

  useEffect(() => {
    getSocketData();
  }, []);

  const getSocketData = () => {
    socket.on("connect", () => {
      // console.log(socket.connected, socket.id);
    });
    socket.on("EnterpriseConnectionResponse", (payload) => {
      // console.log(payload);
    });
    socket.on("InterviewUpdateResponse", (payload) => {
      if (props.interviewToken === payload.interviewToken) {
        setQuestionSet(payload.assessmentUserState);
        setQuestionSetResponse(payload.assessmentUserState);
        setCurrentQuestion((prev) =>
          payload.assessmentUserState.find((x) => {
            if (prev._id === x._id) {
              x.options.filter((y) => {
                y.is_correct === true &&
                  y.selected === true &&
                  (x.correctlyAnswered = true);
              });
              return x;
            }
          })
        );
      }
    });
    socket.on("SyncAssessmentResponse", (payload) => {
      if (props.interviewToken === payload.result.interviewToken) {
        setCurrentQuestion(payload.result);
        setCurrentQuestionNo(payload.result.quesNo);
      }
    });
    socket.on("SyncReportResponse", (payload) => {
      if (props.interviewToken === payload.result.interviewToken) {
        const assessmentSubmissionData = {
          assessmentSubmissionData: payload,
        };
        const assessmentUserState = {
          assessmentUserState: questionSetResponse || [],
        };
        const tabData = { ...assessmentSubmissionData, ...props.tabData };
        if (
          props.interviewData.interviewRoundId.companyId ===
          isAuthenticated().eid
        ) {
          let tabs = payload.result.interviewRoundData.tabs;
          tabs.filter((x) => {
            x.name = x.tabType.includes("coding")
              ? "Coding"
              : x.tabType.includes("white_board")
              ? "Whiteboard"
              : x.tabType.includes("diagram")
              ? "Diagram"
              : "Assessment";
            x.id = parseInt(x.tabID);
            x.img = x.tabType.includes("coding")
              ? PythonCodingWhite
              : x.tabType.includes("white_board")
              ? WhiteboardWhite
              : x.tabType.includes("diagram")
              ? DiagramActiveIcon
              : AssessmentWhite;
            x.panelComponent = {
              data: "",
            };
          });
          tabs.filter((x) => {
            if (x.tabID.toString() === payload.result.tabID.toString()) {
              props.tabsChange(x);
            }
          });
          setCurrentQuestionNo(2);
        }
      }
    });
  };
  return (
    <div className="mockContainer">
      {Object.keys(props.tabData.assessmentSubmissionData || {})?.length > 0 &&
      showresult ? (
        <></>
      ) : (
        <div className="d-flex justify-content-between mockHeadingSection">
          <h5
            className={`InterviewModuleAssessmentTitle ${
              props.interviewData.interviewRoundId.companyId !==
                isAuthenticated().eid && "mx-auto"
            }`}
          >
            {questionSetData && questionSetData.title}
          </h5>
          {props.interviewData.interviewRoundId.companyId ===
            isAuthenticated().eid && (
            <div>
              <span>
                <img
                  src={correctIcon}
                  className="img-fluid"
                  loading="lazy"
                  alt="correctIcon"
                />{" "}
                Correct Answer
              </span>
              <span>
                <img
                  src={userSelection}
                  alt="userSelection"
                  className="img-fluid"
                  loading="lazy"
                />{" "}
                User Selction
              </span>
              <span>
                <img
                  src={wrongIcon}
                  alt="wrongIcon"
                  className="img-fluid"
                  loading="lazy"
                />{" "}
                Wrong Answerd
              </span>
              <span>
                <img
                  src={unattendedIcon}
                  alt="unattendedIcon"
                  className="img-fluid"
                  loading="lazy"
                />{" "}
                Unattended
              </span>
            </div>
          )}
        </div>
      )}

      <div className="mockSubContainer position-relative">
        {Object.keys(props.tabData.assessmentSubmissionData || {})?.length >
          0 && showresult ? (
          <>
            <div className="col-11 mx-auto mb-4 resultPassed text-center">
              <div className="resultPassedHeader">
                {questionSetData && questionSetData.title}
              </div>
              {props.interviewData.interviewRoundId.companyId ===
                isAuthenticated().eid && <div className="py-3 d-flex justify-content-center align-items-center ">
                <div
                  className={`blurBackground ${
                    Math.ceil(
                      props.tabData.assessmentSubmissionData.totalPercent
                    ) > 60
                      ? "blurGreen"
                      : "blurRed"
                  }`}
                ></div>

                <div
                  className={`scoreInPercentBg ${
                    Math.ceil(
                      props.tabData.assessmentSubmissionData.totalPercent
                    ) > 60
                      ? "bgGreen"
                      : "bgRed"
                  }`}
                >
                  <div
                    className={`scoreInPercentMain ${
                      Math.ceil(
                        props.tabData.assessmentSubmissionData.totalPercent
                      ) > 60
                        ? "mainGreen"
                        : "mainRed"
                    }`}
                  >
                    {Math.ceil(
                      props.tabData.assessmentSubmissionData.totalPercent
                    ) + "%"}
                  </div>
                </div>
              </div>}
              <h4 className={`mt-3 ${props.isReportPdf ? "pb-4" : "pb-5"}`}>
                {props.interviewData.interviewRoundId.companyId === isAuthenticated().eid ? "Candidate has Completed the Assessment Successfully" : "You have successfully completed this assessment"}
              </h4>
              {props.interviewData.interviewRoundId.companyId ===
                isAuthenticated().eid && <div className="interviewerQuestionStatushead d-flex align-items-center justify-content-center mb-5">
                <div className="questionsCurrentStatus">
                  Total Questions<span>{questionSet.length}</span>
                </div>
                <div className="questionsCurrentStatus">
                  Answered Correct
                  <span>
                    {props.tabData.assessmentSubmissionData.correctlyAnswered}
                  </span>
                </div>
                <div className="questionsCurrentStatus">
                  Answered Wrong{" "}
                  <span>
                    {props.tabData.assessmentSubmissionData.questionsAttempted -
                      props.tabData.assessmentSubmissionData.correctlyAnswered}
                  </span>
                </div>
                <div className="questionsCurrentStatus">
                  Unattended
                  <span>
                    {" "}
                    {questionSet.length -
                      props.tabData.assessmentSubmissionData.questionsAttempted}
                  </span>
                </div>
              </div>}
            </div>
            {/* {props.interviewData.interviewRoundId.interviewer ===
              isAuthenticated().userId &&
              !props.isReportPdf && (
                <div
                  className={`mx-auto py-2 d-flex col-10 justify-content-between`}
                >
                  {
                    <button
                      className="prevBtn"
                      onClick={() => setShowresult(false)}
                    >
                      Previous
                    </button>
                  }
                </div>
              )} */}
          </>
        ) : (
          <>
            <div className="questionSectionAssess">
              {props.interviewData.interviewRoundId.companyId ===
                isAuthenticated().eid && (
                <div className="m-4 questionset-conatiner col-10 pb-4 mx-auto">
                  <div className="interviewerQuestionStatushead d-flex align-items-center">
                    <p className="questionsoutof">
                      Questions Left{" "}
                      <span>
                        {currentQuestionNo} out of{" "}
                        {questionSet && questionSet.length}
                      </span>
                    </p>

                    <div className="questionsCurrentStatus">
                      Answered Correct
                      <span>
                        {props.tabData.assessmentSubmissionData
                          ?.correctlyAnswered
                          ? props.tabData.assessmentSubmissionData
                              ?.correctlyAnswered
                          : 0}
                      </span>
                    </div>
                    <div className="questionsCurrentStatus">
                      Answered Wrong{" "}
                      <span>
                        {isNaN(
                          props.tabData.assessmentSubmissionData
                            ?.questionsAttempted -
                            props.tabData.assessmentSubmissionData
                              ?.correctlyAnswered
                        )
                          ? 0
                          : props.tabData.assessmentSubmissionData
                              ?.questionsAttempted -
                            props.tabData.assessmentSubmissionData
                              ?.correctlyAnswered}
                      </span>
                    </div>
                    <div className="questionsCurrentStatus">
                      Unattended
                      <span>
                        {isNaN(
                          questionSet.length -
                            props.tabData.assessmentSubmissionData
                              ?.questionsAttempted
                        )
                          ? 0
                          : questionSet.length -
                            props.tabData.assessmentSubmissionData
                              ?.questionsAttempted}
                      </span>
                    </div>
                  </div>
                  <div className="interviewQuestionsStatusbar">
                    {questionSet && (
                      <div
                        className=""
                        style={{
                          width: `${
                            (100 * currentQuestionNo) / questionSet.length
                          }%`,
                        }}
                      ></div>
                    )}
                  </div>
                  <div style={{ height: "auto" }}>
                    <div className="blockQuestionList d-flex flex-wrap">
                      {questionSet &&
                        questionSet.length > 0 &&
                        questionSet.map((questionDetail, index) => (
                          <div
                            onClick={(e) => {
                              setCurrentQuestion(questionDetail);
                              setCurrentQuestionNo(index + 1);
                            }}
                            className="text-center"
                            key={questionDetail._id}
                          >
                            {questionDetail.isVisited === true ? (
                              <div
                                className={`m-3 position-relative ${
                                  questionDetail.isReviewed === true
                                    ? "questionBlock-review text-center"
                                    : "questionBlock text-center"
                                }`}
                              >
                                <p className="">{index + 1}</p>
                                {questionDetail?.correctlyAnswered === true && (
                                  <img
                                    className="mr-2 img-fluid questionNumberStatus"
                                    src={correctQ}
                                    alt="correct"
                                    loading="lazy"
                                  />
                                )}
                                {questionDetail?.correctlyAnswered ===
                                  false && (
                                  <img
                                    className="mr-2 img-fluid questionNumberStatus"
                                    src={wrongQ}
                                    alt="wrong"
                                    loading="lazy"
                                  />
                                )}
                                {!questionDetail.isReviewed &&
                                  !questionDetail.isAnswered && (
                                    <img
                                      className="questionNumberStatus img-fluid"
                                      alt="unattendedQ"
                                      src={unattendedQ}
                                      loading="lazy"
                                    />
                                  )}
                              </div>
                            ) : (
                              <div
                                className={`m-3 position-relative ${
                                  questionDetail.isReviewed === true
                                    ? "questionBlock-review text-center"
                                    : "questionBlock text-center"
                                }`}
                              >
                                <p className="">{index + 1}</p>
                                {questionDetail?.correctlyAnswered === true && (
                                  <img
                                    className="mr-2 img-fluid questionNumberStatus"
                                    src={correctQ}
                                    alt="correct"
                                    loading="lazy"
                                  />
                                )}
                                {questionDetail?.correctlyAnswered ===
                                  false && (
                                  <img
                                    className="mr-2 img-fluid questionNumberStatus"
                                    src={wrongQ}
                                    alt="wrong"
                                    loading="lazy"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`mx-auto m-4 p-4 currentQuesHold ${
                  props.interviewData.interviewRoundId.companyId ===
                  isAuthenticated().eid
                    ? "col-10"
                    : "col-10"
                }`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      currentQuestion?.description?.length >
                      currentQuestion?.title?.length
                        ? currentQuestion?.description
                        : currentQuestion?.title,
                  }}
                ></p>

                {currentQuestion && currentQuestion.type === "CODING" && (
                  <div className="codeBlock">
                    <span>Complete the code to return the output</span>
                    <div className="codeBlockMain">
                      <span className="py-1 mb-0">Predictions =</span>
                      <div
                        className="monacoEditormocks"
                        style={{
                          width: "auto",
                          backgroundColor: "#494d59",
                        }}
                      >
                        <CodeMirrorEditor
                          onChange={(editor, data, value) => {
                            setUserCode(value);
                          }}
                          autoScroll
                          options={{
                            mode: "text/x-python",
                            theme: "monokai",
                            lineWrapping: true,
                            smartIndent: true,
                            lineNumbers: true,
                            foldGutter: true,
                            tabSize: 2,
                            gutters: [
                              "CodeMirror-linenumbers",
                              "CodeMirror-foldgutter",
                            ],
                            autoCloseTags: true,
                            matchBrackets: true,
                            autoCloseBrackets: true,
                            extraKeys: {
                              "Ctrl-Space": "autocomplete",
                            },
                          }}
                          value={currentQuestion.coding.code}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={(e) => CompileCode(userCode)}
                          className="my-3 codeComplier"
                        >
                          RUN
                        </button>
                      </div>
                      <div className="col-12 ml-4 mt-1 mb-4 codeBlockAnswer px-4">
                        {statusAfterCompile &&
                          statusAfterCompile.statusAfterCompile &&
                          statusAfterCompile.statusAfterCompile.message && (
                            <>
                              <h4 style={{ color: "#e9505c" }} className="py-4">
                                Output
                              </h4>
                              <span>
                                {statusAfterCompile &&
                                  statusAfterCompile.statusAfterCompile &&
                                  statusAfterCompile.statusAfterCompile.message}
                              </span>
                            </>
                          )}
                        {statusAfterCompile && statusAfterCompile.message && (
                          <>
                            <h4 style={{ color: "#e9505c" }} className="py-4">
                              Complie Error
                            </h4>
                            <span>
                              {statusAfterCompile && statusAfterCompile.message}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {currentQuestion &&
                  currentQuestion.type === "MCQ" &&
                  currentQuestion.options &&
                  currentQuestion.options.length > 0 &&
                  currentQuestion.options.map((option, index) => (
                    <div
                      className={`p-2 d-flex align-items-center mock-radio position-relative ${
                        option.selected &&
                        props.interviewData.interviewRoundId.companyId ===
                          isAuthenticated().eid
                          ? option.is_correct
                            ? "userSelectionCntrCorrect"
                            : "userSelectionCntrWrong"
                          : ""
                      }`}
                      key={option._id}
                    >
                      {props.interviewData.interviewRoundId.companyId !==
                      isAuthenticated().eid ? (
                        <input
                          className="me-2"
                          type="radio"
                          name="options"
                          value={option.option}
                          onChange={handleChange}
                          checked={option.selected}
                        />
                      ) : (
                        <div
                          className="me-2 interviewerRadiobutton"
                          style={{
                            border: `${
                              (typeof option?.selected === "boolean") && option.is_correct
                                ? "1px solid #5cb85c"
                                : "1px solid #c4c4c4"
                            }`,
                          }}
                        >
                          {typeof option?.selected === "boolean" && <div
                            className=""
                            style={{
                              background: `${
                                option.is_correct ? "#5cb85c" : ""
                              }`,
                            }}
                          ></div>}
                        </div>
                      )}
                      <span>{option.option}</span>
                      {option.selected &&
                        props.interviewData.interviewRoundId.companyId ===
                          isAuthenticated().eid && (
                          <span className="candidateSelectionTxt">
                            Candidate Selection
                          </span>
                        )}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`mx-auto py-2 d-flex justify-content-center submitSection
           ${
             props.interviewData.interviewRoundId.companyId ===
             isAuthenticated().eid
               ? " col-10"
               : " col-10"
           }`}
            >
              {currentQuestionNo > 1 && (
                <button className="prevBtn" onClick={(e) => goToPrevQuestion()}>
                  <img
                    src={rightWhiteArrow}
                    loading="lazy"
                    className="img-fluid"
                    alt="rightWhiteArrow"
                  />{" "}
                  Previous
                </button>
              )}
              <div className="">
                {questionSet && questionSet.length != currentQuestionNo && (
                  <button
                    className="ml-3 submitBtn"
                    onClick={(e) => goToNextQuestion()}
                  >
                    Next{" "}
                    <img
                      src={rightWhiteArrow}
                      loading="lazy"
                      className="img-fluid"
                      alt="rightWhiteArrow"
                    />
                  </button>
                )}
                {questionSet &&
                  questionSet?.length === currentQuestionNo &&
                  props.interviewData.interviewRoundId.companyId !==
                    isAuthenticated().eid && (
                    <button
                      className="ml-3 submitBtn"
                      onClick={() => {
                        let userState = questionSetResponse;
                        submitQuestionResponse(userState);
                      }}
                    >
                      Submit
                    </button>
                  )}
                {questionSet &&
                  questionSet?.length === currentQuestionNo &&
                  props.interviewData.interviewRoundId.companyId ===
                    isAuthenticated().eid &&
                  Object.keys(props.tabData.assessmentSubmissionData || {})
                    ?.length > 0 && (
                    <button
                      className="ml-3 submitBtn"
                      onClick={() => {
                        setShowresult(true);
                      }}
                    >
                      Result
                    </button>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
