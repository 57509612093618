import React from "react";
import { LoadingShape } from "react-rainbow-components";
import "./HackathonUserDetails.css";

function HackathonUserDetails({
  isLoading,
  isLeaderBoardLoading,
  userInfo,
  uId,
}) {
  return (
    <div className="hackathonUserDetails d-flex justify-content-between">
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
        userInfo?.Experience && (
          <div className="col paragraph">
            Experience
            <div>
              {userInfo?.Experience}
              &nbsp;Yrs
            </div>
          </div>
        )
      )}

      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
        userInfo?.currentCtc && (
          <div className="col paragraph">
            Current CTC
            <div>{userInfo?.currentCtc} LPA</div>
          </div>
        )
      )}
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
        userInfo?.LatestDesignation && (
          <div className="col paragraph">
            Designation
            <div>{userInfo?.LatestDesignation}</div>
          </div>
        )
      )}
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
        userInfo?.Organization && (
          <div className="col paragraph">
            Company
            <div>{userInfo?.Organization}</div>
          </div>
        )
      )}
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
        userInfo?.city && (
          <div className="col paragraph">
            Location
            <div>{userInfo?.city}</div>
          </div>
        )
      )}
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
        userInfo?.noticePeriod && (
          <div className="col paragraph">
            Notice Period
            <div>{userInfo?.noticePeriod} Days</div>
          </div>
        )
      )}
    </div>
  );
}

export default HackathonUserDetails;
